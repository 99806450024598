.campaign-content#voucher-section {
    background-color: #0f0f0f;
}
.campaign-language-selector.campaign-content#voucher-section .voucher-main-container{
    background-image: url('../images/screen-01.png');
}
.campaign-form-selector.campaign-content#voucher-section .voucher-main-container{
    background-image: url('../images/screen-02.png');
}
.campaign-content#voucher-section .voucher-main-container{
    max-width: 500px;
    background-image: url('../images/campaign-background.png');
    width: 100%;
    min-height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 60px 0;
}

.campaign-content#voucher-section .voucher-main-container .logo {
    width: 100%;
    margin: 25px auto 20px;
}
.campaign-content#voucher-section .voucher-main-container .event-sub-title {
    color: #ffffff;
    text-align: center;
    font-size: 28px;
    font-weight: 700;
    margin: 20px auto;
}

.campaign-content#voucher-section .voucher-main-container .logo img {
    width: 100%;
    max-width: 100px;
    display: block;
    margin: 0 auto;
}
.campaign-language-selector.campaign-content#voucher-section .voucher-main-container{
    padding: 0 25px;
}
.campaign-language-selector.campaign-content#voucher-section .voucher-main-container .form-container{
    background: none;
    position: relative;
    padding: 50px 0;
    max-width: 380px;
}
.campaign-language-selector.campaign-content#voucher-section .voucher-main-container .form-container .form-container-wrapper{
    position: relative;
    z-index: 2;
}
.campaign-language-selector.campaign-content#voucher-section .voucher-main-container .form-container::before{
    content: "";
    background: rgb(201,157,85);
    background: -moz-linear-gradient(133deg, rgba(201,157,85,1) 0%, rgba(186,138,67,1) 100%);
    background: -webkit-linear-gradient(133deg, rgba(201,157,85,1) 0%, rgba(186,138,67,1) 100%);
    background: linear-gradient(133deg, rgba(201,157,85,1) 0%, rgba(186,138,67,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#c99d55",endColorstr="#ba8a43",GradientType=1);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    border-radius: 25px;
    opacity: 0.75;
}
.campaign-language-selector.campaign-content#voucher-section .voucher-main-container .form-container .header{
    width: 100%;
    margin: 0 0 60px;
    padding: 10px 20px;
    position: relative;
}
.campaign-language-selector.campaign-content#voucher-section .voucher-main-container .form-container .header::before{
    content: "";
    background: #fff;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    opacity: 0.3;
}
.campaign-language-selector.campaign-content#voucher-section .voucher-main-container .form-container .header p{
    color: #28282D;
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    position: relative;
    line-height: 1;
    margin: 8px 0;
    z-index: 2;
}
.campaign-language-selector.campaign-content#voucher-section .voucher-main-container .form-container .footer{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.campaign-language-selector.campaign-content#voucher-section .voucher-main-container .form-container .footer .button{
    max-width: 200px;
    width: 100%;
    margin: 10px auto;
    background-color: #28282D;
    border-radius: 5px;
    padding: 5px;
    color: #fff;
    border: 0;
    outline: none;
    font-size: 16px;
    font-weight: 600;
    box-shadow: none;
    direction: rtl;
    text-decoration: none;
    display: inline-block;
    text-align: center;
    cursor: pointer;
}
.campaign-content#voucher-section .voucher-main-container .form-container .form-field-wrapper{
    position: relative;
    background-color: #23282C;
    padding: 30px 60px 50px;
    max-width: 380px;
    width: 100%;
    border-radius: 18px;
}

.campaign-content#voucher-section .voucher-main-container .form-container .form-heading h2 {
    color: #FFFFFF;
    text-align: center;
    font-size: 28px;
    font-weight: 700;
}

.campaign-content#voucher-section .voucher-main-container .form-container .form-input .form-group {
    margin: 0 0 10px;
}

.campaign-content#voucher-section .voucher-main-container .form-container .form-input .form-group label {
    color: #FFFFFF;
    display: inline-block;
    font-size: 16px;
    font-weight: 600;
    margin: 0 0 5px;
}

.campaign-content#voucher-section .voucher-main-container .form-container .form-input .form-group input {
    border: 0;
    outline: 0;
    border: 0;
    color: #23282C;
    font-size: 16px;
    height: 28px;
    margin: 0;
    outline: none;
    transition: all .3s ease-in-out;
    width: 100%;
    background: #c99d55!important;
    padding: 5px 10px;
    height: auto;
    border-radius: 5px;
}

.campaign-content .error {
    font-size: 12px;
    color: #ff315a;
    margin: 5px 0 0;
}

.campaign-content .dot {
    height: 8px;
    width: 8px;
    background-color: #FFFFFF;
    border-radius: 50%;
    display: inline-block;
    margin-right: 8px;
    margin-bottom: 5px;
}
body.RTL .campaign-content .dot {
    margin-right: 0;
    margin-left: 8px;
}

.campaign-content .main-btn {
    width: 200px;
    border-radius: 5px;
    color: #FFFFFF;
    background-color: #23282C;
    border: 2px solid #C99D55;
    height: 42px;
    font-weight: 500;
    margin: 20px auto 0;
    cursor: pointer;
    display: block;
}
.campaign-content .finish-btn {
    width: 200px;
    border-radius: 20px;
    color: #FFFFFF;
    background-color: #C99D55;
    border: none;
    height: 42px;
    margin: 20px auto 0;
    cursor: pointer;
    display: block;
}
.campaign-content .reset-btn {
    width: 200px;
    border-radius: 5px;
    color: #FFFFFF;
    background-color: #23282C;
    border: 2px solid #C99D55;
    height: 42px;
    font-weight: 500;
    margin: 20px auto 0;
    cursor: pointer;
    display: block;
}

.campaign-content .phone-number-container {
    display: flex;
    gap: 10px;
}

.campaign-content .phone-number-container .country-code,
.campaign-content .phone-number-container .phone-input {
    border: none;
    background-color: transparent;
    font-size: 16px;
    outline: none;
    color: #FFFFFF;
    width: 100%;
}

.campaign-content .phone-number-container .country-code:focus,
.campaign-content .phone-number-container .phone-input:focus {
    border-bottom-color: #007bff;
}

.campaign-content .phone-number-container .react-tel-input .flag-dropdown {
   display: none;
}
.campaign-content .phone-number-container .react-tel-input .selected-flag{
    padding: 0;
}
.campaign-content .phone-number-container .react-tel-input .selected-flag .flag{
    scale: 1.6;
}
.campaign-content .phone-number-container .react-tel-input .selected-flag .flag .arrow{
    display: none;
}

.campaign-content .phone-number-container .react-tel-input .flag-dropdown.open .selected-flag, 
.campaign-content .phone-number-container .react-tel-input .flag-dropdown.open, 
.campaign-content .phone-number-container .react-tel-input .selected-flag:hover, 
.campaign-content .phone-number-container .react-tel-input .selected-flag:focus {
    background-color: transparent;
    background: none;
  }
.campaign-content .phone-number-container .react-tel-input .form-control {
    padding-left: 0px
}
.campaign-content .phone-number-container .react-tel-input .country-list{
    background-color: #0f0f0f;
}
.campaign-content .phone-number-container .react-tel-input .country-list .country {
    padding: 6px 10px;
}
.campaign-content .phone-number-container .react-tel-input .country-list .country.highlight,
.campaign-content .phone-number-container .react-tel-input .country-list .country:hover {
    background-color: #23282C;
}
.campaign-content .phone-number-container .react-tel-input .country-list .country .dial-code {
    color: #c8c8c8;
}

.campaign-content .country-code .form-control {
    border: none;
    border-bottom: 2px solid #ccc;
    background-color: transparent;
    outline: none;
    color: #FFFFFF;
}

.campaign-content .country-code .form-control:focus {
    border-bottom-color: #007bff;
}

.campaign-content .react-tel-input .flag-dropdown.open .selected-flag {
    background-color: none !important;
}


.campaign-content #voucher-code .voucher-code-header {
    display: flex;
    align-items: center;
    position: relative;
}

.campaign-content .divider {
    color: #23282C;
    box-shadow: #E0E0E029 0px 1px 5px;
}

.campaign-content.voucher-content{
    background-color: #0f0f0f;
}
.campaign-content.voucher-content #voucher-code{
    background-image: url('../images/screen-03.png');
    padding: 30px 25px 250px;
    max-width: 500px;
    width: 100%;
    min-height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    margin: 0 auto;
}
.campaign-content.voucher-content.thankyou-content #voucher-code{
    padding: 30px 25px 30px;
}
.campaign-content.voucher-content #voucher-code .QR-Code{
    text-align: center;
    position: absolute;
    bottom: 0;
    left: 50%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    transform: translateX(-50%);
    background: rgb(150,104,52);
    background: -moz-linear-gradient(180deg, rgba(150,104,52,1) 0%, rgba(48,45,43,1) 100%);
    background: -webkit-linear-gradient(180deg, rgba(150,104,52,1) 0%, rgba(48,45,43,1) 100%);
    background: linear-gradient(180deg, rgba(150,104,52,1) 0%, rgba(48,45,43,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#966834",endColorstr="#302d2b",GradientType=1);
}
.campaign-content.voucher-content #voucher-code .QR-Code p{
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    background-color: #c0924b;
    padding: 5px;
    margin: 15px auto 10px;
}
.campaign-content.voucher-content #voucher-code .QR-Code img{
    width: 100%;
    max-width: 140px;
    margin: 0 auto 20px;
    display: block;
}
.campaign-content.voucher-content #voucher-code .QR-dark-Code{
    background-color: #c0924b;
    max-width: 160px;
    width: 100%;
    display: flex;
    margin: 0 auto 40px;
    border-radius: 10px;
    padding: 10px 0px;
}
.campaign-content.voucher-content #voucher-code .QR-dark-Code img{
    width: 100%;
    max-width: 140px;
    margin: 0 auto;
    display: block;
}
.campaign-content.voucher-content #voucher-code .logo{
    max-width: 100px;
    width: 100%;
    margin: 25px auto 30px;
}
.campaign-content.voucher-content #voucher-code .content{
    text-align: center;
}
.campaign-content.voucher-content #voucher-code .content h2{
    margin: 50px 0 0;
    font-size: 58px;
    color: #fff;
}
.campaign-content.voucher-content #voucher-code .content p{
    margin: 0 0 50px;
    font-size: 22px;
    color: #c0924b;
}

.campaign-content.voucher-content #voucher-code  .logo img {
    width: 100%;
}

.campaign-content #voucher-code .voucher-code-header .back-icon {
    width: 10px;
    position: absolute;
    top: 50%;
    left: 25px;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.campaign-content #voucher-code .voucher-code-header .back-icon img {
    width: 100%;
    object-fit: cover;
  }

.campaign-content #voucher-code .voucher-code-header .voucher-heading {
    color: #FFFFFF;
    text-align: center;
    flex: 1;
}
.campaign-content #voucher-code .voucher-code-header .voucher-heading h2{
   margin: 20px 0px;
   font-size: 22px;
}

.campaign-content #voucher-code .voucher-code-header .line {
    color: #23282C;
}

.campaign-content #voucher-code .voucher-code-container .voucher-main-image { 
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    max-width: 380px;
    width: 100%;
    min-height: 200px;
    padding: 30px 0;
    margin: 0 auto;
    position: relative;
    text-align: center;
    --mask:
    radial-gradient(30px at 30px 30px,#0000 calc(98% - 5px),#000 calc(100% - 5px) 98%,#0000) -30px -30px,
    linear-gradient(90deg,#000 10px,#0000 0) -5px 50% /100% calc(100% - 60px + 5px) repeat-x,
    linear-gradient(      #000 10px,#0000 0) 50% -5px/calc(100% - 60px + 5px) 100% repeat-y;
}
.campaign-content #voucher-code .voucher-code-container .voucher-main-image:before {
    content: "";
    position: absolute;
    inset: 0;
    background: #C99D55;
    -webkit-mask: var(--mask);
            mask: var(--mask);
  }

.campaign-content #voucher-code .voucher-code-container .voucher-main-image p {
    display: inline-block;
    margin: 20px auto 0;
    font-size: 22px;
    font-weight: 600;
    line-height: 1.2;
    color: #ffffff;
    max-width: 300px;
}
.campaign-content #voucher-code .voucher-code-container .voucher-main-image img {
    width: 100%;
    margin: 20px 0;
}
.campaign-content #voucher-code .voucher-code-container .voucher-main-image .code-value {
    padding: 5px 30px;
    border-radius: 5px;
    display: inline-block;
    margin: 0 auto;
    font-size: 28px;
    color: #23282C;
    margin: 0 auto 20px;
    background: rgb(241,198,107);
    background: -moz-linear-gradient(133deg, rgba(241,198,107,1) 0%, rgba(134,87,39,1) 24%, rgba(245,204,116,1) 54%, rgba(221,177,103,1) 75%, rgba(139,93,39,1) 100%);
    background: -webkit-linear-gradient(133deg, rgba(241,198,107,1) 0%, rgba(134,87,39,1) 24%, rgba(245,204,116,1) 54%, rgba(221,177,103,1) 75%, rgba(139,93,39,1) 100%);
    background: linear-gradient(133deg, rgba(241,198,107,1) 0%, rgba(134,87,39,1) 24%, rgba(245,204,116,1) 54%, rgba(221,177,103,1) 75%, rgba(139,93,39,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f1c66b",endColorstr="#8b5d27",GradientType=1); 
}
.campaign-content #voucher-code .voucher-code-container .message{
    padding: 5px 10px;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    border-radius: 10px;
    display: block;
    border: 3px solid #c99d55;
    max-width: 380px;
    width: 100%;
    margin: 30px auto 0;
    color: #ffffff;
}

.campaign-content .heading-container {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 600px;
    padding: 20px;
}

.campaign-content .heading {
    margin: 0 15px;
    font-size: 20px;
    text-align: center;
    color: #FFFFFF;
    font-weight: 400;
}

.campaign-content .line {
    flex: 1;
    height: 1.5px;
    background-color: #FFFFFf;
}

.campaign-content .heading-main-box {
    display: flex;
    justify-content: center;
    align-items: center;
}