#quickRegister{
    padding: 20px 0px;
    background-image: url(../images/inner-bg-image.png);
    background-size: cover;
    background-repeat: no-repeat;
}
#quickRegister .quickRegister-wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
#quickRegister .quickRegister-wrapper .quick-register-body{
    max-width: 800px;
    margin: 0 auto 35px;
    width: 100%;
}
#quickRegister .quickRegister-wrapper .quick-register-body .quick-register-form .form-fields {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
#quickRegister .quickRegister-wrapper .quick-register-body .quick-register-form .form-group{
    max-width: 48%;
    width: 100%;
    margin-bottom: 10px;
}
#quickRegister .quickRegister-wrapper .quick-register-body .quick-register-form .form-group.logo-container{
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 60px;
}
#quickRegister .quickRegister-wrapper .quick-register-body .quick-register-form .form-group.logo-container label{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
    width: 150px;
    height: 150px;
}
#quickRegister .quickRegister-wrapper .quick-register-body .quick-register-form .form-group.logo-container label input{
    display: none
}
#quickRegister .quickRegister-wrapper .quick-register-body .quick-register-form .form-group.logo-container label img{
    width: 150px;
    height: 150px;
    min-width: 150px;
    min-height: 150px;
    max-width: 150px;
    max-height: 150px;
    border-radius: 150px;
    margin-bottom: 10px;
    object-fit: cover;
}
#quickRegister .quickRegister-wrapper .quick-register-body .quick-register-form .form-group.file-container{
    width: 100%;
    max-width: 100%;
}
#quickRegister .quickRegister-wrapper .quick-register-body .quick-register-form .form-group.file-container .file-list{
    display: grid;
    grid-template-columns: 150px 150px 150px 150px 150px;
    gap: 10px;
}
#quickRegister .quickRegister-wrapper .quick-register-body .quick-register-form .form-group.file-container .file-list label{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 150px;
    height: 150px;
    border-radius: 5px;
    font-size: 30px;
    background-color: #e4e4e4;
    font-weight: 500;
    margin: 0 10px 10px 0;
}
body.RTL #quickRegister .quickRegister-wrapper .quick-register-body .quick-register-form .form-group.file-container .file-list label{
    margin: 0 0 10px 10px;
}
#quickRegister .quickRegister-wrapper .quick-register-body .quick-register-form .form-group.file-container .file-list > label:last-of-type,
body.RTL #quickRegister .quickRegister-wrapper .quick-register-body .quick-register-form .form-group.file-container .file-list label:last-of-type{
    margin: 0 0 10px 0;
}
#quickRegister .quickRegister-wrapper .quick-register-body .quick-register-form .form-group.file-container .file-list label input{
    display: none;
}
#quickRegister .quickRegister-wrapper .quick-register-body .quick-register-form .form-group label{
    display: inline-block;
    margin-bottom: 10px;
    color: #fff;
    font-weight: 500;
}
#quickRegister .quickRegister-wrapper .quick-register-body .quick-register-form .form-group label span:not(.text-gray-200):not(.text-white):not(.file-info){
    color: #f15b5b;
}
#quickRegister .quickRegister-wrapper .quick-register-body .quick-register-form .form-group .file-list label span:not(.text-gray-200):not(.file-info){
    color: #555;
}
#quickRegister .quickRegister-wrapper .quick-register-body .quick-register-form .form-group .file-list label span.file-info{
    display: block;
    text-align: center;
    color: #c29f60;
}
#quickRegister .quickRegister-wrapper .quick-register-body .quick-register-form .form-group .file-list label span.file-info h6{
    font-size: 14px;
    font-weight: 500;
    margin: 10px 0 0 0;
}
#quickRegister .quickRegister-wrapper .quick-register-body .quick-register-form .form-group .file-list label span.file-info p{
    font-size: 14px;
    font-weight: 600;
    margin: 5px 0 0 0;
}
#quickRegister .quickRegister-wrapper .quick-register-body .quick-register-form .form-group .PhoneInput{
    width: 100%;
    margin: 0px;
    border: 1px solid #707070;
    padding: 0px 20px;
    font-size: 16px;
    outline: none;
    box-shadow: none;
    border-radius: 5px;
    background: #fff;
    color: #444;
    transition: all 0.3s ease-in-out;
}
#quickRegister .quickRegister-wrapper .quick-register-body .quick-register-form .form-group .PhoneInput.PhoneInput--focus{
    border: 1px solid #80BD65;
    transition: all 0.3s ease-in-out;
}
#quickRegister .quickRegister-wrapper .quick-register-body .quick-register-form .form-group .PhoneInput input{
    border: 0;
    box-sizing: none;
    outline: none;
    padding: 12.5px 0px;
}
#quickRegister .quickRegister-wrapper .quick-register-body .quick-register-form .form-group .customselect > div:first-of-type{
    border-color: #cdcdcd;
    box-shadow: none!important;
}
#quickRegister .quickRegister-wrapper .quick-register-body .quick-register-form .form-group .customselect > div:first-of-type:focus,
#quickRegister .quickRegister-wrapper .quick-register-body .quick-register-form .form-group .customselect > div:first-of-type:hover{
    border-color: var(--primary);
    box-shadow: none!important;
}
#quickRegister .quickRegister-wrapper .quick-register-body .quick-register-form .form-group .customselect .customSelectOptions__multi-value{
    background-color: var(--primary);
}
#quickRegister .quickRegister-wrapper .quick-register-body .quick-register-form .form-group .customselect .customSelectOptions__multi-value .customSelectOptions__multi-value__label{
    color: #fff;
}
#quickRegister .quickRegister-wrapper .quick-register-body .quick-register-form .form-group .customselect .customSelectOptions__multi-value .customSelectOptions__multi-value__remove{
    color: #fff;
    cursor: pointer;
}
#quickRegister .quickRegister-wrapper .quick-register-body .quick-register-form .form-group .customselect .customSelectOptions__multi-value .customSelectOptions__multi-value__remove:hover {
    background-color: var(--primary);
    color: #fff;
}
#quickRegister .quickRegister-wrapper .quick-register-body .quick-register-form .form-group .PhoneInput select{
    background-image: none;
}
#quickRegister .quickRegister-wrapper .quick-register-body .quick-register-form .form-group .PhoneInput .PhoneInputCountrySelectArrow{
    display: none;
}
#quickRegister .quickRegister-wrapper .quick-register-body .quick-register-form .form-group select,
#quickRegister .quickRegister-wrapper .quick-register-body .quick-register-form .form-group input[type="date"],
#quickRegister .quickRegister-wrapper .quick-register-body .quick-register-form .form-group input[type="number"],
#quickRegister .quickRegister-wrapper .quick-register-body .quick-register-form .form-group input[type="email"],
#quickRegister .quickRegister-wrapper .quick-register-body .quick-register-form .form-group input[type="text"]{
    display: block;
    width: 100%;
    margin: 0px;
    border: 1px solid #707070;
    padding: 10px 20px;
    font-size: 16px;
    outline: none;
    box-shadow: none;
    border-radius: 5px;
    background: #fff;
    color: #444;
    transition: all 0.3s ease-in-out;
}
#quickRegister .quickRegister-wrapper .quick-register-body .quick-register-form .form-group select{
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: -1px;
    text-overflow: '';
    background-image: url(../images/arrow-down.png);
    background-repeat: no-repeat;
    background-size: 12px;
    background-position: calc( 100% - 15px ) center;
}
#quickRegister .quickRegister-wrapper .quick-register-body .quick-register-form .form-group select:focus{
    border: 1px solid #80BD65;
    transition: all 0.3s ease-in-out;
}
body.RTL #quickRegister .quickRegister-wrapper .quick-register-body .quick-register-form .form-group select{
    background-position: 15px center;
}
#quickRegister .quickRegister-wrapper .quick-register-body .quick-register-form .form-group select::-ms-expand {
    display: none;
}
#quickRegister .quickRegister-wrapper .quick-register-body .quick-register-form .form-group select option{
    font-family: 'Courier New';
}
#quickRegister .quickRegister-wrapper .quick-register-body .quick-register-form .form-group input[type="date"]:focus,
#quickRegister .quickRegister-wrapper .quick-register-body .quick-register-form .form-group input[type="number"]:focus,
#quickRegister .quickRegister-wrapper .quick-register-body .quick-register-form .form-group input[type="email"]:focus,
#quickRegister .quickRegister-wrapper .quick-register-body .quick-register-form .form-group input[type="text"]:focus{
    border: 1px solid #80BD65;
    transition: all 0.3s ease-in-out;
}
#quickRegister .quickRegister-wrapper .quick-register-body .quick-register-form .form-group .field-error{
    color: #f15b5b;
    font-size: 14px;
    margin: 5px 0 0;
}

@media (max-width: 880px){
    #quickRegister .quickRegister-wrapper .quick-register-body .quick-register-form .form-group.file-container .file-list{
        display: grid;
        grid-template-columns: 120px 120px 120px 120px 120px;
        gap: 10px;
    }
    #quickRegister .quickRegister-wrapper .quick-register-body .quick-register-form .form-group .file-list label span.file-info i{
        font-size: 22px;
    }
    #quickRegister .quickRegister-wrapper .quick-register-body .quick-register-form .form-group .file-list label span.file-info h6,
    #quickRegister .quickRegister-wrapper .quick-register-body .quick-register-form .form-group .file-list label span.file-info p {
        font-size: 12px;
        margin: 5px 0 0 0;
    }
    #quickRegister .quickRegister-wrapper .quick-register-body .quick-register-form .form-group.file-container .file-list label {
        width: 120px;
        height: 120px;
    }
}
@media (max-width: 767px){
    #quickRegister .quickRegister-wrapper .quick-register-body .quick-register-form .form-group.file-container .file-list{
        grid-template-columns: 120px 120px 120px;
    }
}
@media (max-width: 625px){
    #quickRegister .quickRegister-wrapper .quick-register-body .quick-register-form .form-group{
        max-width: 100%
    }
    #quickRegister .quickRegister-wrapper .quick-register-body .quick-register-form .form-group .file-list label span.file-info i{
        font-size: 22px;
    }
    #quickRegister .quickRegister-wrapper .quick-register-body .quick-register-form .form-group .file-list label span.file-info h6,
    #quickRegister .quickRegister-wrapper .quick-register-body .quick-register-form .form-group .file-list label span.file-info p {
        font-size: 12px;
        margin: 5px 0 0 0;
    }
    #quickRegister .quickRegister-wrapper .quick-register-body .quick-register-form .form-group.file-container .file-list label {
        width: 120px;
        height: 120px;
    }
}
@media (max-width: 450px){
    #quickRegister .quickRegister-wrapper .quick-register-body .quick-register-form .form-group.file-container .file-list{
        grid-template-columns: 100px 100px 100px;
    }
    #quickRegister .quickRegister-wrapper .quick-register-body .quick-register-form .form-group.file-container .file-list label {
        width: 100px;
        height: 100px;
      }
}
@media (max-width: 350px){
    #quickRegister .quickRegister-wrapper .quick-register-body .quick-register-form .form-group.file-container .file-list{
        grid-template-columns: 80px 80px 80px;
    }
    #quickRegister .quickRegister-wrapper .quick-register-body .quick-register-form .form-group.file-container .file-list label {
        width: 80px;
        height: 80px;
      }
}