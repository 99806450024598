.reset-page{
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background:linear-gradient(40deg,#000000c3,#000000c3),url("../images/reset-password.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    overflow-y: auto;

}
.reset-page .reset-card{
    background-color: white;
    padding: 50px;
    width: 450px;
    min-height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 5px;
    margin: 20px;
    box-shadow: 0 3px 10px rgba(255, 255, 255, 0.486);
}
.reset-page .reset-card img.logo{
    max-width: 200px;
    
}
.reset-page .reset-card p{
    margin-top: 0;
    text-align: center;
    margin-bottom: 20px;
    color: #828282;
}
.reset-page .reset-card h2{
    margin-bottom: 10px;
    font-weight: 500;

}

.reset-page .reset-card input{
    width: 100%;
    padding: 15px 12px;
    border-radius: 5px;
    margin-bottom: 10px;
    outline: none;
    border: 2px solid #dedede;
    transition: 0.2s ease-in-out;
    padding-right: 40px;
}
.reset-page .reset-card input:focus{
    border: 2px solid #C29F60;
    transition: 0.2s ease-in-out;
}

.reset-page .reset-card  button#submit-reset-pass{
    cursor: pointer;
    border-radius: 0px;
    width: 100%;
    display: block;
    border-radius: 5px;
    padding: 15px 12px;
    min-height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.reset-page .reset-card  button#submit-reset-pass:hover{
    background-color: transparent;
    color: #C29F60;
    border: 1px solid #C29F60;
    transition: all 0.3s ease-in-out;
}

/* Image Animation  */
/* .heartbeat{-webkit-animation:heartbeat 2.5s ease-in-out infinite both;animation:heartbeat 2.5s ease-in-out infinite both}
@-webkit-keyframes heartbeat{from{-webkit-transform:scale(1);transform:scale(1);-webkit-transform-origin:center center;transform-origin:center center;-webkit-animation-timing-function:ease-out;animation-timing-function:ease-out}10%{-webkit-transform:scale(.91);transform:scale(.91);-webkit-animation-timing-function:ease-in;animation-timing-function:ease-in}17%{-webkit-transform:scale(.98);transform:scale(.98);-webkit-animation-timing-function:ease-out;animation-timing-function:ease-out}33%{-webkit-transform:scale(.87);transform:scale(.87);-webkit-animation-timing-function:ease-in;animation-timing-function:ease-in}45%{-webkit-transform:scale(1);transform:scale(1);-webkit-animation-timing-function:ease-out;animation-timing-function:ease-out}}@keyframes heartbeat{from{-webkit-transform:scale(1);transform:scale(1);-webkit-transform-origin:center center;transform-origin:center center;-webkit-animation-timing-function:ease-out;animation-timing-function:ease-out}10%{-webkit-transform:scale(.91);transform:scale(.91);-webkit-animation-timing-function:ease-in;animation-timing-function:ease-in}17%{-webkit-transform:scale(.98);transform:scale(.98);-webkit-animation-timing-function:ease-out;animation-timing-function:ease-out}33%{-webkit-transform:scale(.87);transform:scale(.87);-webkit-animation-timing-function:ease-in;animation-timing-function:ease-in}45%{-webkit-transform:scale(1);transform:scale(1);-webkit-animation-timing-function:ease-out;animation-timing-function:ease-out}} */

.reset-page .reset-card .error-input{
    border: 2px solid red!important;
}
.reset-page p.fail{
    padding: 0 20px;   
}
.reset-page h1.fail{
    text-align: center;
    color: #f94d4d;
    font-size: 2rem;
}
.reset-page small.error{
    color: #f94d4d;
    display: block;
    width: 100%;
    margin-bottom: 8px;
    font-weight: 500;
}
.reset-page h1.success{
    text-align: center;
    color: rgb(57, 171, 57);
    font-size: 2rem;
}
.reset-page img.status{
    max-width: 100px;
}

.error-points{
    margin: 10px 0px;
    padding: 0 0 0 20px;
    list-style-position: outside;
}


@media(max-width:520px){
    .reset-page .reset-card{
        width: 85vw;
        padding: 25px;
    }
}

.reset-page .input-group{
    position: relative;
    width: 100%;
}
.reset-page .input-group .show-pwd-icon{
    position: absolute;
    right: 0;
    /* height: calc(100% - 10px); */
    background-color: transparent;
    outline: none;
    border: none;
    cursor: pointer;
    margin-top: 14px;
    margin-right: 10px;
}


/* Loader Styling  */



/*
To view/get the compiled css click the dropwdown in the top right
*/

  #loading-spinner {
    display: block;
    margin: 0 auto;
    -webkit-animation: loading-spinner-spin 2s linear infinite;
            animation: loading-spinner-spin 2s linear infinite;
    will-change: transform;
  }
  
  @-webkit-keyframes loading-spinner-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  @keyframes loading-spinner-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  #loading-circle {
    stroke-dasharray: 105;
    stroke-dashoffset: 105;
    stroke-linecap: round;
    -webkit-animation: loading-spinner-small 1.7s cubic-bezier(0.445, 0.05, 0.55, 0.95) infinite;
            animation: loading-spinner-small 1.7s cubic-bezier(0.445, 0.05, 0.55, 0.95) infinite;
    transform: translateZ(0);
    transform-origin: center;
    will-change: stroke-dashoffset;
  }
  
  @-webkit-keyframes loading-spinner-small {
    0% {
      stroke-dashoffset: 95;
      transform: scaleY(1);
    }
    49.99% {
      stroke-dashoffset: 0;
      transform: scaleY(1);
    }
    50% {
      stroke-dashoffset: 0;
      transform: scaleY(-1) rotate(25deg);
    }
    100% {
      stroke-dashoffset: 95;
      transform: scaleY(-1) rotate(-32deg);
    }
  }
  
  @keyframes loading-spinner-small {
    0% {
      stroke-dashoffset: 95;
      transform: scaleY(1);
    }
    49.99% {
      stroke-dashoffset: 0;
      transform: scaleY(1);
    }
    50% {
      stroke-dashoffset: 0;
      transform: scaleY(-1) rotate(25deg);
    }
    100% {
      stroke-dashoffset: 95;
      transform: scaleY(-1) rotate(-32deg);
    }
  }

  button.disabled-btn{
    opacity: 0.8;
  }