#umrah-badal-page,body.dark-bg,html.dark-bg {
    background-color: #23282C;
}
body.dark-bg p,
body.dark-bg h1,
body.dark-bg h2,
body.dark-bg h3,
body.dark-bg h4,
body.dark-bg h5,
body.dark-bg h6,
body.dark-bg span,
body.dark-bg i,
body.dark-bg ul,
body.dark-bg ul li,
body.dark-bg ol,
body.dark-bg ol li,
body.dark-bg strong,
body.dark-bg b{
  font-family: "IBM Plex Sans Arabic", sans-serif;
}

#umrahBadal-hero .hero-img {
    position: relative;
    width: 100%;
    min-height: 100vh;
    padding: 50px 0px;
    background-image: url('../../assets/images/umraAlbadal.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #23282C;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

}

#umrahBadal-hero .hero-img .tawkeel-gold {
    width: 100%;
    text-align: center;
}

#umrahBadal-hero .hero-img .tawkeel-gold .img {
    width: 360px;
    height: 135px;
}

#umrahBadal-hero .hero-img .tawkeel-gold .umrah-heading {
    font-size: 85px;
    color: #FFFFFF;
    margin: 50px 0 0;
}

#banifitsUmrahabadal .umrah-badal-content-wrapper {
    margin-top: 40px;
}

#banifitsUmrahabadal .umrah-badal-content-wrapper h2 {
    color: #C99D55;
    font-size: 32px;
    margin-bottom: 18px;
    font-weight: 500;
}

#banifitsUmrahabadal .umrah-badal-content-wrapper h4 {
    color: #C99D55;
    font-size: 24px;
    margin: 11px 0;
    font-weight: 400;
}

#banifitsUmrahabadal .umrah-badal-content-wrapper h3 {
    color: #FFFFFF;
    font-size: 24px;
    margin: 11px 0;
    font-weight: 400;
}

#banifitsUmrahabadal .umrah-badal-content-wrapper p {
    font-weight: 300;
    font-size: 20px;
    color: #FFFFFF;
    margin-top: 0;
    margin-bottom: 12px;
}

#banifitsUmrahabadal .umrah-badal-content-wrapper .caption {
    font-weight: 300;
    font-size: 16px;
    color: #FFFFFF;
    padding: 40px 10px 10px 10px;
}

#banifitsUmrahabadal .umrah-badal-content-wrapper ul li {
    font-weight: 300;
    font-size: 18px;
    color: #FFFFFF;
    margin-top: 0;
    margin-bottom: 12px;
}

#banifitsUmrahabadal .umrah-badal-content-wrapper .italic-bold-quotaion {
    font-size: 22px;
    font-weight: 500;
}

#banifitsUmrahabadal .umrah-badal-content-wrapper .container-fatwas {
    padding: 4px;
    background-color: #2D3135;
    border-radius: 10px;
}

#banifitsUmrahabadal .umrah-badal-content-wrapper .fatwas-video {
    width: 100%;
    border-radius: 20px;

}

#umrah-badal-page .faqBody-wrapper .faq-listing .single-faq {
    border: 2px solid #C99D55 !important;

}

#umrah-badal-page .faqBody-wrapper .faq-listing .single-faq .faq-question {
    color: #C99D55 !important;
}

#umrah-badal-page .faqBody-wrapper .faq-listing .single-faq .faq-answer p {
    color: #FFFFFF !important;
}

#umrah-badal-page .faqBody-wrapper .secondary-heading {
    color: #C99D55;
    font-size: 32px;
}

@media screen and (max-width : 1080px) {
    #banifitsUmrahabadal .umrah-badal-content-wrapper .fatwas-video {
        height: 520px;
    }

    #umrahBadal-hero .hero-img .tawkeel-gold {
        bottom: 23px;
    }

    #umrahBadal-hero .hero-img .tawkeel-gold .img {
        width: 300px;
        height: 112px;
    }

    #umrahBadal-hero .hero-img .tawkeel-gold .umrah-heading {
        font-size: 62px;
        font-weight: 500;
    }
}

@media screen and (max-width : 780px) {
    #umrahBadal-hero .hero-img {
        min-height: 80vh;
    }
    #banifitsUmrahabadal .umrah-badal-content-wrapper .fatwas-video {
        height: 375px;
    }

    #umrahBadal-hero .hero-img .tawkeel-gold .img {
        width: 220px;
        height: 80px;
    }

    #umrahBadal-hero .hero-img .tawkeel-gold .umrah-heading {
        font-size: 45px;
        font-weight: 500;
    }

    #banifitsUmrahabadal .umrah-badal-content-wrapper h2 {
        font-size: 25px;
    }

    #banifitsUmrahabadal .umrah-badal-content-wrapper p {
        font-size: 18px;
    }

    #banifitsUmrahabadal .umrah-badal-content-wrapper .italic-bold-quotaion {
        font-size: 20px;
        font-weight: 500;
    }

    #banifitsUmrahabadal .umrah-badal-content-wrapper ul li {
        font-size: 16px;
    }

    #umrah-badal-page .faqBody-wrapper .secondary-heading {
        font-size: 25px;
    }
}

@media screen and (max-width : 475px) {
    #banifitsUmrahabadal .umrah-badal-content-wrapper .fatwas-video {
        height: auto;
    }

    #umrahBadal-hero .hero-img {
        min-height: auto;
    }

    #umrahBadal-hero .hero-img .tawkeel-gold .umrah-heading {
        font-size: 32px;
    }

    #umrahBadal-hero .hero-img .tawkeel-gold .img {
        width: 169px;
        height: 67px;
    }

    #banifitsUmrahabadal .umrah-badal-content-wrapper h2 {
        font-size: 22px;
    }

    #umrah-badal-page .faqBody-wrapper .secondary-heading {
        font-size: 22px;
    }

    #umrahBadal-hero .hero-img .tawkeel-gold {
        bottom: -5px;
    }
}