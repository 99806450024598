footer{
    background-color: #24282C;
}
footer .footer-wrapper .footer-row{
    display: flex;
    justify-content: space-between;
    padding: 50px 0px;
}
footer .footer-wrapper .footer-row .d-inline-block{
    display: inline-block
}

footer .footer-wrapper .footer-row .footer-column{
    max-width: 355px;
    width: 100%;
}
footer .footer-wrapper .footer-row .footer-column .footer-logo{
    margin: 0 0 20px;
    max-width: 165px;
    width: 100%;
}
footer .footer-wrapper .footer-row .footer-column .footer-text{
    font-size: 16px;
}
footer .footer-wrapper .footer-row .footer-column .text-paragraph{
    color: #f7f7f7;
    margin-bottom: 25px;
}
footer .footer-wrapper .footer-row .footer-column .column-header{
    color: #C29D60;
    margin: 0 0 25px;
    font-size: 24px;
    font-weight: 500;
}
footer .footer-wrapper .footer-row .footer-column .btn-group .btn.img-btn{
    height: auto;
}
footer .footer-wrapper .footer-row .footer-column .certificate-list{
    display: flex;
    margin-top: 20px;
}
footer .footer-wrapper .footer-row .footer-column .certificate-list .certificate{
    display: flex;
    align-items: center;
    text-decoration: none;
}
footer .footer-wrapper .footer-row .footer-column .certificate-list .certificate img{
    display: inline-block;
    width: 60px;
    height: 60px;
    object-fit: contain;
    margin-right: 10px;
}
.RTL footer .footer-wrapper .footer-row .footer-column .certificate-list .certificate img{
    margin-right: 0px;
    margin-left: 10px;
}
footer .footer-wrapper .footer-row .footer-column .certificate-list .certificate p{
    font-size: 14px;
    color: #F7F7F7;
    font-weight: 400;
}
footer .footer-wrapper .footer-row .footer-column .certificate-list .certificate p span{
    font-weight: 600;
    color: #fff;
}
footer .footer-wrapper .footer-row .footer-column .additional-logo{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
}
footer .footer-wrapper .footer-row .footer-column .additional-logo img{
    /* max-width: 48%;
    width: 100%; */
    width: 100%;
    height: auto;
    max-width: 90px;
}
footer .btn.img-btn img {
    max-height: 30px;
}
footer .footer-wrapper .footer-row .footer-column .payment-methods{
    display: flex;
    align-items: center;
    margin-top: 30px;
}
footer .footer-wrapper .footer-row .footer-column .payment-methods img{
    max-height: 20px;
    margin-right: 20px;
}
.RTL footer .footer-wrapper .footer-row .footer-column .payment-methods img{
    margin-right: 0px;
    margin-left: 20px;
}
footer .footer-wrapper .footer-row .footer-column .payment-methods > img:last-of-type{
    margin-right: 0px;
}
footer .footer-wrapper .footer-row .footer-column .payment-methods > img:last-of-type{
    margin-right: 0px;
    margin-left: 0px;
}
footer .footer-wrapper .footer-row .footer-column .social-list,
footer .footer-wrapper .footer-row .footer-column .contact-list,
footer .footer-wrapper .footer-row .footer-column .footer-navigation{
    list-style: none;
    margin: 0px;
    padding: 0px;
}
footer .footer-wrapper .footer-row .footer-column .footer-navigation li{
    margin-bottom: 15px;
}
footer .footer-wrapper .footer-row .footer-column .footer-navigation > li:last-of-type{
    margin-bottom: 0px;
}
footer .footer-wrapper .footer-row .footer-column .footer-navigation li a{
    text-decoration: none;
    color: #F7F7F7;
    font-weight: 400;
    font-size: 18px;
    transition: all 0.3s ease-in-out;
}
footer .footer-wrapper .footer-row .footer-column .footer-navigation li a:hover{
    color: #C29F60;
    transition: all 0.3s ease-in-out;
}
footer .footer-wrapper .footer-row .footer-column .contact-list li{
    display: flex;
    align-items: center;
    color: #F7F7F7;
    font-weight: 400;
    font-size: 18px;
    margin-bottom: 15px;
    transition: all 0.3s ease-in-out;
}
footer .footer-wrapper .footer-row .footer-column .contact-list li i{
    margin-right: 15px;
    font-size: 20px;
}
.RTL footer .footer-wrapper .footer-row .footer-column .contact-list li i{
    margin-right: 0px;
    margin-left: 15px;
}
footer .footer-wrapper .footer-row .footer-column .contact-list li a{
    text-decoration: none;
    color: #F7F7F7;
    transition: all 0.3s ease-in-out;
}
footer .footer-wrapper .footer-row .footer-column .contact-list li a:hover{
    transition: all 0.3s ease-in-out;
    color: #C29F60;
}
footer .footer-wrapper .footer-row .footer-column .social-list{
    display: flex;
    align-items: center;
    margin-top: 30px;
}
footer .footer-wrapper .footer-row .footer-column .social-list li{
    border: 1px solid #fff;
    border-radius: 7px;
    height: 40px;
    width: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    transition: all 0.3s ease-in-out;
}
.RTL footer .footer-wrapper .footer-row .footer-column .social-list li{
    margin-right: 0px;
    margin-left: 10px;
}
footer .footer-wrapper .footer-row .footer-column .social-list > li:last-of-type{
    margin-right: 0px;
}
.RTL footer .footer-wrapper .footer-row .footer-column .social-list > li:last-of-type{
    margin-right: 0px;
    margin-left: 0px;
}
footer .footer-wrapper .footer-row .footer-column .social-list li a{
    text-decoration: none;
    color: #fff;
    font-size: 18px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease-in-out;
}
footer .footer-wrapper .footer-row .footer-column .social-list li:hover{
    background-color: #C29F60;
    border: 1px solid #C29F60;
    transition: all 0.3s ease-in-out;
}
footer .footer-wrapper .footer-row .footer-column .social-list li:hover a{
    text-decoration: none;
    color: #fff;
    transition: all 0.3s ease-in-out;
}
footer .footer-wrapper .footer-row .footer-column .btn-apple,
footer .footer-wrapper .footer-row .footer-column .btn-google{
    display: inline-block;
}
footer .footer-wrapper .footer-row .footer-column .btn-apple svg,
footer .footer-wrapper .footer-row .footer-column .btn-google svg{
    max-width: 180px;
}
footer .footer-wrapper .footer-row .footer-column .btn-apple svg path,
footer .footer-wrapper .footer-row .footer-column .btn-google svg path{
    transition: all 0.3s ease-in-out;
}
footer .footer-wrapper .footer-row .footer-column .btn-apple:hover svg .fill,
footer .footer-wrapper .footer-row .footer-column .btn-google:hover svg .fill{
    fill: #3F9A97;
    transition: all 0.3s ease-in-out;
}
footer .footer-wrapper .footer-row .footer-column .btn-apple:hover svg .stroke,
footer .footer-wrapper .footer-row .footer-column .btn-google:hover svg .stroke{
    stroke: #3F9A97;
    transition: all 0.3s ease-in-out;
}
footer .footer-wrapper .footer-row .footer-column .btn-apple:hover svg .fill.light,
footer .footer-wrapper .footer-row .footer-column .btn-google:hover svg .fill.light{
    fill: #fff;
    transition: all 0.3s ease-in-out;
}


footer .copyright{
    background-color: #24282C;
    text-align: center;
    padding: 10px 0;
}
footer .copyright p{
    color: #EDEDED;
    font-size: 14px;
    text-align: center;
    padding: 0px;
    margin: 0px;
    font-weight: 400;
    width: 100%;
    max-width: 1650px;
    border-top: 1px solid #707070;
    margin: 0 auto;
    padding-top: 10px;
}


@media only screen and (max-width: 1420px) {
    footer .footer-wrapper .footer-row .footer-column {
        max-width: 30%;
        width: 100%;
        padding: 0px 10px;
    }
    footer .footer-wrapper .footer-row > div.footer-column:first-of-type {
        max-width: 30%;
    }
    footer .footer-wrapper .footer-row > div.footer-column:nth-of-type(2) {
        max-width: 20%;
    }
    footer .footer-wrapper .footer-row .footer-column .column-header {
        font-size: 22px;
    }
    footer .footer-wrapper .footer-row .footer-column .btn-apple svg, 
    footer .footer-wrapper .footer-row .footer-column .btn-google svg {
        max-width: 150px;
    }
    footer .footer-wrapper .footer-row .footer-column .contact-list li,
    footer .footer-wrapper .footer-row .footer-column .footer-navigation li a {
        font-size: 16px;
    }
}

@media only screen and (max-width: 1220px) {
    /* footer .footer-wrapper .footer-row .footer-column {
        max-width: 20%;
    }
    footer .footer-wrapper .footer-row > div.footer-column:first-of-type {
        max-width: 35%;
    }
    footer .footer-wrapper .footer-row > div.footer-column:nth-of-type(2) {
        max-width: 25%;
    } */
}

@media only screen and (max-width: 1040px){
    footer .footer-wrapper .footer-row .footer-column {
        max-width: 32%;
    }
    footer .footer-wrapper .footer-row > div.footer-column:first-of-type {
        max-width: 100%;
        text-align: center;
    }
    footer .footer-wrapper .footer-row > div.footer-column:nth-of-type(3) {
        max-width: 32%;
    }
    footer .footer-wrapper .footer-row .footer-column .certificate-list {
        align-items: center;
        justify-content: center;
    }
    footer .footer-wrapper .footer-row .footer-column .social-list{
        justify-content: center;
    }
    footer .footer-wrapper .footer-row .footer-column .payment-methods{
        justify-content: center;
    }
    footer .footer-wrapper .footer-row .footer-column .btn-group{
        justify-content: center;
    }
    footer .footer-wrapper .footer-row .footer-column .additional-logo{
        justify-content: center;
    }
    footer .footer-wrapper .footer-row > div.footer-column:nth-of-type(2){
        max-width: 100%;
    }
    footer .footer-wrapper .footer-row .footer-column .certificate-list .certificate {
        flex-direction: column;
        justify-content: center;
        margin: 0 20px;
    }
    .RTL footer .footer-wrapper .footer-row .footer-column .certificate-list .certificate img,
    footer .footer-wrapper .footer-row .footer-column .certificate-list .certificate img {
        margin-right: 0px;
        margin-left: 0px;
        width: 100px;
        height: 100px;
    }
    footer .footer-wrapper .footer-row .footer-column .certificate-list .certificate p {
        font-size: 16px;
        margin-top: -10px;
    }
    footer .footer-wrapper .footer-row > div.footer-column:first-of-type .payment-methods {
        justify-content: center;
    }
}
@media only screen and (max-width: 800px){

    footer .footer-wrapper .footer-row .footer-column {
        max-width: 49%;
    }
    footer .footer-wrapper .footer-row > div.footer-column:first-of-type {
        max-width: 49%;
        text-align: left;
    }
    .RTL footer .footer-wrapper .footer-row > div.footer-column:first-of-type {
        text-align: right;
    }
    footer .footer-wrapper .footer-row > div.footer-column:nth-of-type(3) {
        max-width: 49%;
    }
    footer .footer-wrapper .footer-row .footer-column .certificate-list {
        align-items: start;
        justify-content: start;
    }
    footer .footer-wrapper .footer-row .footer-column .certificate-list .certificate {
        flex-direction: row;
        margin: 0;
    }
    footer .footer-wrapper .footer-row .footer-column .certificate-list .certificate img {
        margin-right: 10px;
        width: 60px;
        height: 60px;
    }
    RTL footer .footer-wrapper .footer-row .footer-column .certificate-list .certificate img{
        margin-right: 0px;
        margin-left: 10px;
    }
    footer .footer-wrapper .footer-row .footer-column .certificate-list .certificate p {
        font-size: 14px;
        margin-top: 0px;
        margin-bottom: 0px;
    }
}
@media only screen and (max-width: 719px){

    .RTL footer .footer-wrapper .footer-row > div.footer-column:first-of-type,
    footer .footer-wrapper .footer-row .footer-column {
        max-width: 100%;
        text-align: center;
    }
    footer .footer-wrapper .footer-row > div.footer-column:first-of-type {
        text-align: center;
        max-width: 100%;
    }
    footer .footer-wrapper .footer-row > div.footer-column:nth-of-type(3) {
        max-width: 100%;
    }
    footer .footer-wrapper .footer-row > div.footer-column:last-of-type {
        display: inline-block;
    }
    footer .footer-wrapper .footer-row .footer-column .btn-apple
    footer .footer-wrapper .footer-row .footer-column .btn-google {
        display: inline-block;
    }
    footer .footer-wrapper .footer-row .footer-column .btn-google {
        margin-right: 10px;
    }
    body.RTL footer .footer-wrapper .footer-row .footer-column .btn-google {
        margin-right: 0px;
        margin-left: 10px;
    }
    footer .footer-wrapper .footer-row > div.footer-column:nth-of-type(2) {
        margin-bottom: 50px
    }
    footer .footer-wrapper .footer-row .footer-column .certificate-list {
        align-items: center;
        justify-content: center;
    }
    footer .footer-wrapper .footer-row .footer-column .certificate-list .certificate {
        flex-direction: column;
        margin: 0 20px;
    }
    .RTL footer .footer-wrapper .footer-row .footer-column .certificate-list .certificate img, 
    footer .footer-wrapper .footer-row .footer-column .certificate-list .certificate img {
        margin-right: 0px;
        margin-left: 0px;
        width: 100px;
        height: 100px;
    }
    footer .footer-wrapper .footer-row .footer-column .contact-list li, 
    footer .footer-wrapper .footer-row .footer-column .footer-navigation li a {
        font-size: 14px;
        justify-content: center;
    }
    footer .footer-wrapper .footer-row .footer-column .social-list {
        justify-content: center;
    }
}