#agents {
    padding: 150px 0px 100px;
}

#agents .agent-list {
    width: 100%;
    display: grid;
    grid-template-columns: 24% 24% 24% 24%;
    justify-content: space-between;
}
#agents .text-paragraph {
    font-size: 19px;
}

#agents .agent-list .single-agent {
    background: #62626280 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 20px;
    position: relative;
    margin-bottom: 20px;
    box-shadow: 0px 5px 10px 0px #0005;
}

#agents .agent-list .single-agent .agent-header {
    /* background: rgb(63,154,151);
    background: -moz-linear-gradient(90deg, rgba(63,154,151,1) 0%, rgba(128,189,101,1) 100%);
    background: -webkit-linear-gradient(90deg, rgba(63,154,151,1) 0%, rgba(128,189,101,1) 100%);
    background: linear-gradient(90deg, rgba(63,154,151,1) 0%, rgba(128,189,101,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#3f9a97",endColorstr="#80bd65",GradientType=1); */
    /* max-width: calc(100% - 60px); */
    width: 100%;
    /* margin: 0 auto;
    transform: translateY(-50px);
    box-shadow: 0px 5px 10px 0px #c7eae9;
    border-radius: 10px; */
    padding: 20px;
}

#agents .agent-list .single-agent .agent-header img {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 80px;
    display: block;
    margin: 0 auto;
}

#agents .agent-list .single-agent .agent-header .agent-name {
    font-size: 20px;
    color: #fff;
    text-align: center;
    margin-bottom: 0;
    font-weight: 600;
}

#agents .agent-list .single-agent .agent-header .agent-cr {
    font-size: 16px;
    color: #fff;
    text-align: center;
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 400;
}

#agents .agent-list .single-agent .agent-header .agent-city-country {
    font-size: 16px;
    color: #fff;
    text-align: center;
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 400;
}

#agents .agent-list .single-agent .agent-body .info-line {
    display: flex;
    margin-bottom: 10px;
}

#agents .agent-list .single-agent .agent-body .info-line i {
    margin-right: 10px;
    font-size: 22px;
    color: #CECECE;
}

.RTL #agents .agent-list .single-agent .agent-body .info-line i {
    margin-right: 0px;
    margin-left: 10px;
}

#agents .agent-list .single-agent .agent-body .info-line .info-text {
    font-size: 16px;
    font-weight: 500;
    margin: 0;
    color: #CECECE;
    text-decoration: none;
}

@media (max-width: 1100px) {
    #agents .agent-list {
        grid-template-columns: 32% 32% 32%;
    }
}

@media (max-width: 900px) {
    #agents .agent-list .single-agent .agent-header .agent-name {
        font-size: 18px;
    }
}
@media (max-width: 800px) {
    #agents .agent-list {
        grid-template-columns: 48% 48%;
    }
}
@media (max-width: 500px) {
    #agents .agent-list {
        grid-template-columns: 100%;
    }
}
@media (max-width: 420px) {
    #agents .agent-list .single-agent .agent-body .info-line .info-text {
        font-size: 14px;
    }
}