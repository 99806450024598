header {
    position: absolute;
    z-index: 99;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #C99D55;
}

/* topbar */
header .topbar {
    background-color: #24282C;
    position: relative;
    z-index: 10;
}

header .topbar .topbar-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 25px;
}

header .topbar .topbar-wrapper .contact-label {
    color: #FFFFFF;
    font-weight: 500;
}

header .topbar .topbar-wrapper .topbar-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.topbar-whatsapp,
.topbar-phone {
    color: #fff;
}

header .topbar .btn-lang {
    color: #fff;
}

/* top bar end */
header .header-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 30px;
    padding-bottom: 30px;
    position: relative;
}

header .header-wrapper.inner-header-wrapper {
    justify-content: space-between;
    padding-top: 13px;
    padding-bottom: 10px;
}

.header-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 137px;
    background-color: #C99D55;
    mix-blend-mode: plus-lighter;
    z-index: -1;
}

.header-overlay.inner-header-overlay {
    height: 114px;
}

header .header-wrapper .header-left {
    display: flex;
    align-items: center;
}

header .header-wrapper .header-left .header-logo {
    max-width: 100px;
    width: 100%;
    margin-right: 35px;
    display: inline-block;
}

.RTL header .header-wrapper .header-left .header-logo {
    margin-left: 35px;
    margin-right: 0;
}

header .header-wrapper .header-left .header-logo img {
    width: 100%;
    max-width: 85px;
    margin: 0px;
    padding: 0px;
}

header .header-wrapper .header-left .header-navigation .navigation-menu-items {
    list-style: none;
    margin: 0px;
    padding: 0px;
    display: flex;
    align-items: center;
}

header .header-wrapper .header-left .header-navigation .navigation-menu-items li.menu-item {
    margin-right: 35px;
}

.RTL header .header-wrapper .header-left .header-navigation .navigation-menu-items li.menu-item {
    margin-left: 35px;
    margin-right: 0;
}

header .header-wrapper .header-left .header-navigation .navigation-menu-items li.menu-item.mobile {
    display: none;
}

header .header-wrapper .header-left .header-navigation .navigation-menu-items>li.menu-item:last-of-type {
    margin-right: 0px;
    margin-left: 0px;
}

.RTL header .header-wrapper .header-left .header-navigation .navigation-menu-items>li.menu-item:last-of-type {
    margin-right: 0px;
    margin-left: 0px;
}

header .header-wrapper .header-left .header-navigation .navigation-menu-items li.menu-item span {
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
}

header .header-wrapper .header-left .header-navigation .navigation-menu-items li.menu-item a {
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
}

header .header-wrapper .header-left .header-navigation .navigation-menu-items li.menu-item a:hover {
    /* color: #C29F60; */
    color: #F2E7D5;
    transition: all 0.3s ease-in-out;
}

header .header-wrapper .header-left .header-navigation .btn-close {
    display: none;
    position: fixed;
    top: 55px;
    right: 45px;
    background: none;
    color: #444;
    cursor: pointer;
    border: 0px;
    outline: none;
    font-size: 24px;
}

.navbar-show header .header-wrapper .header-left .header-navigation .btn-close {
    display: inline-flex;
}

header .header-wrapper .header-right {
    display: flex;
    align-items: center;
}

header .mobile-toggle {
    display: none;
    color: #fff;
    font-size: 28px;
    cursor: pointer;
    margin-left: 20px;
}

body.RTL header .mobile-toggle {
    margin-left: 0px;
    margin-right: 20px;
}

@media only screen and (max-width: 1420px) {
    header .header-wrapper .header-left .header-navigation .navigation-menu-items li.menu-item {
        margin-right: 25px;
    }
}

@media only screen and (max-width: 1200px) {

    header .header-wrapper .header-left .header-navigation .navigation-menu-items li.menu-item span,
    header .header-wrapper .header-left .header-navigation .navigation-menu-items li.menu-item a {
        font-size: 16px;
    }

    header .header-wrapper .header-left .header-logo {
        max-width: 80px;
    }
}

@media only screen and (max-width: 1140px) {

    /* header .header-wrapper{
        display: none;
    } */
    header {
        background-color: transparent !important;
    }
    .header-overlay:not(.inner-header-overlay) {
        display: none;
    }

    header .header-wrapper .header-left .header-navigation .btn-close {
        align-items: center;
    }

    header .mobile-toggle {
        display: inline-flex;
        align-items: center;
    }

    header .header-wrapper .header-left .header-navigation {
        position: fixed;
        background: #fffd;
        left: 0;
        right: 0;
        top: -100vh;
        bottom: 100vh;
        opacity: 0;
        transition: all 0.3s ease-in-out;
    }

    body.navbar-show header .header-wrapper .header-left .header-navigation {
        top: 0;
        bottom: 0;
        opacity: 1;
        transition: all 0.3s ease-in-out;
    }

    header .header-wrapper .header-left .header-navigation .navigation-menu-items {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    header .header-wrapper .header-left .header-navigation .navigation-menu-items li.menu-item {
        margin-right: 0;
        margin-bottom: 20px;
    }

    header .header-wrapper .header-left .header-navigation .navigation-menu-items li.menu-item span {
        color: #C29F60;
        border: 1px solid #C29F60;
        padding: 10px 20px;
        border-radius: 10px;
        font-size: 22px;

    }

    header .header-wrapper .header-left .header-navigation .navigation-menu-items li.menu-item a {
        color: #C29F60;
        font-size: 22px;
    }
}

@media only screen and (max-width: 700px) {
    header .header-wrapper .header-right .btn-group a.btn {
        font-size: 0;
        padding: 0;
        width: 40px;
        text-align: center;
        display: inline-flex;
        align-items: center;
        justify-content: center;
    }

    header .header-wrapper .header-right .btn-group a.btn svg {
        margin-right: 0px;
    }

    header .header-wrapper .header-left .header-navigation .navigation-menu-items li.menu-item a {
        font-size: 14px;
    }

    header .header-wrapper .header-left .header-navigation .navigation-menu-items li.menu-item span {
        padding: 5px 14px;
        font-size: 14px;
    }
}