.contract-content{
    display: block;
    margin: 20px 0px;
    border: 1px solid #ddd;
    padding: 20px;
}
.contract-content figure.table{
    padding: 0;
    margin: 0;
}
.contract-content figure.table table{
    border-collapse: collapse;
}
.contract-content figure.table table thead tr th{
    background: #dddd;
    padding: 5px;
}
.contract-content figure.table table tbody tr td{
    padding: 10px;
    border: 1px solid #ddd;
}
#contract .secondary-heading{
    color: #C29D60;
}
#contract .text-paragraph{
    color: #828282;
}