#package #publicPackageForm .form-section.section-one{
    background-image: url(../images/package-background.png);
    background-position: left;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 50px;
    padding-bottom: 50px;
    min-height: 100vh;
}
#package #publicPackageForm .form-section.section-one .section-container{
    max-width: 700px;
    width: 100%;
    background-color: #C89D5533;
    padding: 40px 70px;
    border-radius: 40px;
}
#package #publicPackageForm .form-section.section-one .section-container .section-header .form-logo{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 25px;
}
#package #publicPackageForm .form-section.section-one .section-container .section-header .section-headings{
    text-align: center;
    margin-top: 40px;
}
#package #publicPackageForm .form-section.section-one .section-container .section-header .section-headings h1{
    font-size: 48px;
    margin-bottom: 0;
}
#package #publicPackageForm .form-section.section-one .section-container .section-header .section-headings h2{
    font-size: 18px;
    margin-top: 0;
}
#package #publicPackageForm .form-section.section-one .section-container .section-form-groups{
    max-width: 400px;
    width: 100%;
    margin: 30px auto 0;
    text-align: center;
}
#package #publicPackageForm .form-section.section-one .section-container .section-form-groups .form-group{
    max-width: 400px;
    width: 100%;
    margin: 0 auto 30px;
}
#package #publicPackageForm .form-section.section-one .section-container .section-form-groups .form-group h4{
   font-size: 22px;
   margin-bottom: 15px;
}
#package #publicPackageForm .form-section.section-one .section-container .section-form-groups .form-group input{
    width: 100%;
    border-radius: 50px;
    padding: 15px 20px;
    border: 0px;
    outline: none;
    box-shadow: none;
    background-color: #C29E60;
    color: #ffffff;
}
#package #publicPackageForm .btn.btn-dark{
    background-color: #23282C;
    border-radius: 50px;
    padding: 10px 40px;
    border: 0;
    box-shadow: none;
    outline: none;
    color: #ffffff;
    text-align: center;
    font-size: 16px;
    display: inline-block;
    height: auto;
}

#package #publicPackageForm .form-section.section-two{
    background-image: url(../images/package-background-lines.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 50px;
    padding-bottom: 50px;
    min-height: calc( 100vh - 20px );
    border-top: 20px solid #C29E60;
}
#package #publicPackageForm .form-section.section-two .section-container .section-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 50px;
}
#package #publicPackageForm .form-section.section-two .section-container .section-header .form-logo{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 25px;
}
#package #publicPackageForm .form-section.section-two .section-container .section-header .section-headings h1{
    font-size: 42px;
    margin: 0;
}
#package #publicPackageForm .form-section.section-two .section-container .section-header .section-headings h2{
    font-size: 18px;
    margin: 0;
}
#package #publicPackageForm .form-section.section-two .section-container .section-body{
    background-color: #f4ebdd;
    padding: 40px;
    border-radius: 30px;
}
#package #publicPackageForm .form-section.section-two .section-container .section-body .form-packages table tr{
    background: none;
}
#package #publicPackageForm .form-section.section-two .section-container .section-body .form-packages table tr th{
    border: 2px solid #23282C;
    background-color: #C29E60;
    color: #23282C;
    font-family: "IBM Plex Sans Arabic", sans-serif;
    font-weight: 600;
    font-size: 18px;
}
#package #publicPackageForm .form-section.section-two .section-container .section-body .form-packages table tr td{
    border: 2px solid #23282C;
    background: none;
    color: #23282C;
    font-family: "IBM Plex Sans Arabic", sans-serif;
    font-weight: 400;
    font-size: 18px;
}
.RTL #package #publicPackageForm .form-section.section-two .section-container .section-body .account-heading{
    background: rgb(2,0,36);
    background: -moz-linear-gradient(90deg, rgba(2,0,36,0) 0%, rgba(194,158,96,1) 100%);
    background: -webkit-linear-gradient(90deg, rgba(2,0,36,0) 0%, rgba(194,158,96,1) 100%);
    background: linear-gradient(90deg, rgba(2,0,36,0) 0%, rgba(194,158,96,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#020024",endColorstr="#c29e60",GradientType=1);
}
#package #publicPackageForm .form-section.section-two .section-container .section-body .account-heading{
    display: inline-block;
    font-family: "IBM Plex Sans Arabic", sans-serif;
    font-weight: 500;
    background: rgb(2,0,36);
    background: -moz-linear-gradient(90deg,  rgba(194,158,96,1) 0%, rgba(2,0,36,0) 100%);
    background: -webkit-linear-gradient(90deg,  rgba(194,158,96,1) 0%,  rgba(2,0,36,0) 100%);
    background: linear-gradient(90deg,rgba(194,158,96,1) 0%,  rgba(2,0,36,0)  100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#c29e60",endColorstr="#020024",GradientType=1);
    padding: 5px 10px;
    font-size: 24px;
    margin: 0 0 20px;
}
#package #publicPackageForm .form-section.section-two .section-container .section-body .account-details{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
#package #publicPackageForm .form-section.section-two .section-container .section-body .account-details .section-label{
    margin: 0 0 10px;
    font-family: "IBM Plex Sans Arabic", sans-serif;
    font-weight: 500;
    text-align: center;
}
#package #publicPackageForm .form-section.section-two .section-container .section-body .account-details .section-data{
    margin: 0;
    font-family: "IBM Plex Sans Arabic", sans-serif;
    font-weight: 500;
    text-align: center;
}

.text-orientation-vertical{
    writing-mode: vertical-rl;
    text-orientation: mixed;
}

@media (max-width: 1400px){
    #package #publicPackageForm .form-section.section-one .section-container{
        margin: 0 auto;
    }
}
@media (max-width: 1024px){
    #package #publicPackageForm .form-section.section-one .section-container {
        max-width: 600px;
        padding: 30px 40px;
        border-radius: 40px;
    }
    #package #publicPackageForm .form-section.section-one .section-container .section-header .form-logo img{
        height: 120px;
    }   
    #package #publicPackageForm .form-section.section-one .section-container .section-header .section-headings h1 {
        font-size: 38px;
    }
    #package #publicPackageForm .form-section.section-one .section-container .section-header .section-headings h2 {
        font-size: 14px;
    }
    #package #publicPackageForm .form-section.section-one .section-container .section-form-groups .form-group {
        margin: 0 auto 15px;
    }
    #package #publicPackageForm .form-section.section-one .section-container .section-form-groups .form-group h4 {
        font-size: 16px;
    }
    #package #publicPackageForm .form-section.section-one .section-container .section-form-groups .form-group input {
        padding: 10px 15px;
    }
    #package #publicPackageForm .form-section.section-two .section-container .section-header .form-logo img{
        height: 100px;
    }
    #package #publicPackageForm .form-section.section-two .section-container .section-header .section-headings h1{
        font-size: 32px;
    }
    #package #publicPackageForm .form-section.section-two .section-container .section-header .section-headings h2{
        font-size: 14px;
    }
    #package #publicPackageForm .form-section.section-two .section-container .section-body {
        padding: 20px;
        border-radius: 10px;
    }
    #package #publicPackageForm .form-section.section-two .section-container .section-body .form-packages table tr th {
        font-size: 14px;
    }
    #package #publicPackageForm .form-section.section-two .section-container .section-body .form-packages table tr td {
        font-size: 14px;
    }
    #package #publicPackageForm .form-section.section-two .section-container .section-body .account-heading{
        font-size: 16px;
    }
    #package #publicPackageForm .form-section.section-two .section-container .section-body .account-details img{
        width: 140px;
    }
    #package #publicPackageForm .form-section.section-two .section-container .section-body .account-details .section-data ,
    #package #publicPackageForm .form-section.section-two .section-container .section-body .account-details .section-label {
        font-size: 14px;
    }
}
@media (max-width: 860px){
    #package #publicPackageForm .form-section.section-two .section-container .section-header .form-logo img {
        height: 80px;
    }
    #package #publicPackageForm .form-section.section-two .section-container .section-body .account-heading {
        font-size: 14px;
    }
    #package #publicPackageForm .form-section.section-two .section-container .section-body .account-details{
        flex-wrap: wrap;
    }
    #package #publicPackageForm .form-section.section-two .btn.btn-dark{
        width: 100%;
        margin-top: 20px;
    }
    #package #publicPackageForm .form-section.section-two .section-container .section-header {
        justify-content: center;
        flex-direction: column-reverse;
    }
    #package #publicPackageForm .form-section.section-two .section-container .section-header .form-logo {
        margin-bottom: 20px;
    }
    #package #publicPackageForm .form-section.section-two .section-container .section-header .section-headings{
        text-align: center;
    }
    #package #publicPackageForm .form-section.section-two .section-container .section-header .section-headings h1 {
        font-size: 28px;
    }
    #package #publicPackageForm .form-section.section-two .section-container .section-body .form-packages table {
        margin-top: 0;
    }
    #package #publicPackageForm .form-section.section-one .section-container .section-header .form-logo img {
        height: 100px;
    }
    #package #publicPackageForm .form-section.section-one .section-container .section-header .section-headings h1 {
        font-size: 28px;
    }
}
@media (max-width: 700px){
    #package #publicPackageForm .form-section.section-two .section-container .section-body .form-packages table tr th {
        padding: 5px;
    }
    #package #publicPackageForm .form-section.section-two .section-container .section-body .form-packages table tr td {
        padding: 5px;
    }
    #package #publicPackageForm .form-section.section-two .section-container .section-body .account-details {
        flex-direction: column;
        justify-content: center;
    }
    #package #publicPackageForm .form-section.section-two .section-container .section-body .account-details > div{
        margin-top: 20px;
    }
    #package #publicPackageForm .form-section.section-two .section-container .section-body .account-heading {
        display: block;
        max-width: 350px;
        margin: 0 auto 20px;
        text-align: center;
    }
}