:root{
  --primary: #3F9A97;
  --secondary: #80BD65;
  --subtitle: #066295;
  --gray-100: #828282;
  --gray-200: #4b4b4b;
}
.text-primary{
  color: var(--primary);
}
.text-secondary{
  color: var(--secondary);
}
.text-subtitle{
  color: var(--subtitle);
}
.text-gray-100{
  color: var(--gray-100);
}
.text-gray-200{
  color: var(--gray-200);
}
.text-white{
  color: #fff;
}
.d-flex{
  display: flex;
}
.flex-direction-col{
  flex-direction: column;
}
.align-items-center{
  align-items: center;
}
.justify-center{
  justify-content: center;
}
.justify-between{
  justify-content: space-between;
}
.justify-start{
  justify-content: flex-start;
}
.fw-100{
  font-weight: 100;
}
.fw-200{
  font-weight: 200;
}
.fw-300{
  font-weight: 300;
}
.fw-400{
  font-weight: 400;
}
.fw-500{
  font-weight: 500;
}
.fw-600{
  font-weight: 600;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #FBFBFD;;
  overflow-x: hidden;
}
.ibm-plex-sans-arabic-thin {
  font-family: "IBM Plex Sans Arabic", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.ibm-plex-sans-arabic-extralight {
  font-family: "IBM Plex Sans Arabic", sans-serif;
  font-weight: 200;
  font-style: normal;
}

.ibm-plex-sans-arabic-light {
  font-family: "IBM Plex Sans Arabic", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.ibm-plex-sans-arabic-regular {
  font-family: "IBM Plex Sans Arabic", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.ibm-plex-sans-arabic-medium {
  font-family: "IBM Plex Sans Arabic", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.ibm-plex-sans-arabic-semibold {
  font-family: "IBM Plex Sans Arabic", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.ibm-plex-sans-arabic-bold {
  font-family: "IBM Plex Sans Arabic", sans-serif;
  font-weight: 700;
  font-style: normal;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

body {
  scrollbar-width: thin;          /* "auto" or "thin" */
  scrollbar-color: #C29F60 #ddd;   /* scroll thumb and track */ 
}

body.fixed{
  overflow: hidden;
}

body.RTL{
  direction: rtl;
}
.ltr{
  direction: ltr!important;
}
.rtl{
  direction: rtl!important;
}

body::-webkit-scrollbar {
  width: 12px;               /* width of the entire scrollbar */
}

body::-webkit-scrollbar-track {
  background: #ddd;        /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
  background-color: #C29F60;    /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
  border: 3px solid #ddd;  /* creates padding around scroll thumb */
}
.text-primary{
  color: #C29F60;
}

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #C29F60 #ddd;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: #ddd;
}

*::-webkit-scrollbar-thumb {
  background-color: #C29F60;
  border-radius: 20px;
  border: 3px solid #ddd;
}

.d-block{
  display: block!important;
}
.cursor-pointer{
  cursor: pointer;
}

.text-ar{
  font-family: "IBM Plex Sans Arabic", sans-serif!important;
}
.text-en{
  font-family: "IBM Plex Sans Arabic", sans-serif!important;
}
.text-default{
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif!important;
}
* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-family: "IBM Plex Sans Arabic", sans-serif;
}
.RTL *{
  font-family: "IBM Plex Sans Arabic", sans-serif;
}
.RTL .escape, .RTL .escape *{
  direction: ltr;
}
.RTL .font-escape, .RTL .font-escape *{
  font-family: "IBM Plex Sans Arabic", sans-serif;
}
.text-center{
  text-align: center;
}
.text-right{
  text-align: right;
}
.text-left{
  text-align: left;
}
.RTL .text-right{
  text-align: left;
}
.RTL .text-left{
  text-align: right;
}
.mt-0{
  margin-top: 0!important;
}
.mt-1{
  margin-top: 0.25rem !important;
}
.mt-2{
  margin-top: 0.5rem !important;
}
.mt-3{
  margin-top: 1rem !important;
}
.mt-4{
  margin-top: 1.5rem !important;
}
.mt-5{
  margin-top: 3rem !important;
}
.mb-0{
  margin-bottom: 0!important;
}
.mb-1{
  margin-bottom: 0.25rem !important;
}
.mb-2{
  margin-bottom: 0.5rem !important;
}
.mb-3{
  margin-bottom: 1rem !important;
}
.mb-4{
  margin-bottom: 1.5rem !important;
}
.mb-5{
  margin-bottom: 3rem !important;
}
.ml-0{
  margin-left: 0!important;
}
.ml-1{
  margin-left: 0.25rem !important;
}
.ml-2{
  margin-left: 0.5rem !important;
}
.ml-3{
  margin-left: 1rem !important;
}
.ml-4{
  margin-left: 1.5rem !important;
}
.ml-5{
  margin-left: 3rem !important;
}
.mr-0{
  margin-right: 0!important;
}
.mr-1{
  margin-right: 0.25rem !important;
}
.mr-2{
  margin-right: 0.5rem !important;
}
.mr-3{
  margin-right: 1rem !important;
}
.mr-4{
  margin-right: 1.5rem !important;
}
.mr-5{
  margin-right: 3rem !important;
}
.pl-0{
  padding-left: 0!important;
}
.pl-1{
  padding-left: 0.25rem !important;
}
.pl-2{
  padding-left: 0.5rem !important;
}
.pl-3{
  padding-left: 1rem !important;
}
.pl-4{
  padding-left: 1.5rem !important;
}
.pl-5{
  padding-left: 3rem !important;
}
.pr-0{
  padding-right: 0!important;
}
.pr-1{
  padding-right: 0.25rem !important;
}
.pr-2{
  padding-right: 0.5rem !important;
}
.pr-3{
  padding-right: 1rem !important;
}
.pr-4{
  padding-right: 1.5rem !important;
}
.pr-5{
  padding-right: 3rem !important;
}
.pt-0{
  padding-top: 0!important;
}
.pt-1{
  padding-top: 0.25rem !important;
}
.pt-2{
  padding-top: 0.5rem !important;
}
.pt-3{
  padding-top: 1rem !important;
}
.pt-4{
  padding-top: 1.5rem !important;
}
.pt-5{
  padding-top: 3rem !important;
}
.pb-0{
  padding-bottom: 0!important;
}
.pb-1{
  padding-bottom: 0.25rem !important;
}
.pb-2{
  padding-bottom: 0.5rem !important;
}
.pb-3{
  padding-bottom: 1rem !important;
}
.pb-4{
  padding-bottom: 1.5rem !important;
}
.pb-5{
  padding-bottom: 3rem !important;
}

.w-100{
  max-width: 100%!important;
  width: 100%!important;
}
.w-95{
  max-width: 95%!important;
  width: 100%!important;
}
.w-90{
  max-width: 90%!important;
  width: 100%!important;
}
.w-85{
  max-width: 85%!important;
  width: 100%!important;
}
.w-80{
  max-width: 80%!important;
  width: 100%!important;
}
.w-75{
  max-width: 75%!important;
  width: 100%!important;
}
.w-70{
  max-width: 70%!important;
  width: 100%!important;
}
.w-65{
  max-width: 65%!important;
  width: 100%!important;
}
.w-60{
  max-width: 60%!important;
  width: 100%!important;
}
.w-55{
  max-width: 55%!important;
  width: 100%!important;
}
.w-50{
  max-width: 50%!important;
  width: 100%!important;
}
.w-45{
  max-width: 45%!important;
  width: 100%!important;
}
.w-40{
  max-width: 40%!important;
  width: 100%!important;
}
.w-35{
  max-width: 35%!important;
  width: 100%!important;
}
.w-30{
  max-width: 30%!important;
  width: 100%!important;
}
.w-25{
  max-width: 25%!important;
  width: 100%!important;
}
.w-20{
  max-width: 20%!important;
  width: 100%!important;
}
.w-15{
  max-width: 15%!important;
  width: 100%!important;
}
.w-10{
  max-width: 10%!important;
  width: 100%!important;
}
.w-5{
  max-width: 5%!important;
  width: 100%!important;
}
.w-0{
  max-width: 0%!important;
  width: 100%!important;
}
.m-0{
  margin: 0px!important;
}
.ml-0{
  margin-left: 0px!important;
}
.mt-0{
  margin-top: 0px!important;
}
.mr-0{
  margin-right: 0px!important;
}
.mb-0{
  margin-bottom: 0px!important;
}
.d-none{
  display: none!important;
}
.d-inline-block{
  display: inline-block!important;
}
.btn{
  font-size: 16px;
  padding: 0px 32px;
  border-radius: 50px;
  display: inline-flex;
  align-items: center;
  outline: none;
  box-shadow: none;
  text-decoration: none;
  font-weight: 500;
  transition: all 0.3s ease-in-out;
  height: 50px;
  cursor: pointer;
}
.btn svg{
  margin-right: 10px;
}
.btn.btn-light{
  background-color: #fff;
  color: #C29E60;
  border: 1px solid #fff;
  transition: all 0.3s ease-in-out;
}
.btn.btn-light.has-icon svg path{
  fill: #C29E60;
  transition: all 0.3s ease-in-out;
}
.btn.btn-light:hover{
  background: none;
  color: #fff;
  border: 1px solid #fff;
  transition: all 0.3s ease-in-out;
}
.btn.btn-light.has-icon:hover svg path{
  fill: #ffffff;
  transition: all 0.3s ease-in-out;
}
.btn.btn-light.has-stroke-icon svg path{
  stroke: #C29E60;
  transition: all 0.3s ease-in-out;
}
.btn.btn-light.has-stroke-icon:hover svg path{
  stroke: #fff;
  transition: all 0.3s ease-in-out;
}
.btn.btn-outline-light{
  color: #fff;
  border: 1px solid #fff;
  transition: all 0.3s ease-in-out;
}
.btn.btn-outline-light.has-stroke-icon svg path{
  stroke: #fff;
  transition: all 0.3s ease-in-out;
}
.btn.btn-outline-light:hover{
  background-color: #fff;
  color: #3F9A97;
  border: 1px solid #fff;
  transition: all 0.3s ease-in-out;
}
.btn.btn-outline-light.has-stroke-icon:hover svg path{
  stroke: #3F9A97;
  transition: all 0.3s ease-in-out;
}
.btn.btn-download{
  background-color: #24282C;
  color: #fff;
  border: 1px solid #24282C;
  transition: all 0.3s ease-in-out;
}
.btn.btn-primary{
  background-color: #C29F60;
  color: #FBFBFD;
  border: 1px solid #C29F60;
  transition: all 0.3s ease-in-out;
}
.btn.btn-outline-primary{
  background-color: transparent;
  color: #C29F60;
  border: 1px solid #C29F60;
  transition: all 0.3s ease-in-out;
}
.btn.btn-primary:disabled{
  background-color: #505050!important;
  color: #FBFBFD!important;
  border: 1px solid #505050!important;
  cursor: not-allowed;
  transition: all 0.3s ease-in-out;
}
.btn.btn-primary:hover{
  background-color: transparent;
  color: #C29F60;
  border: 1px solid #C29F60;
  transition: all 0.3s ease-in-out;
}
.btn.btn-primary-home {
  background-color: #C29F60;
  color: #FBFBFD;
  border: 1px solid #C29F60;
  transition: all 0.3s ease-in-out;
}
.btn.btn-support {
  background-color: #449F02;
  color: #FBFBFD;
  border: 1px solid #449F02;
  transition: all 0.3s ease-in-out;
}
.btn.btn-support:hover {
  background-color: transparent;
  color: #449F02;
  border: 1px solid #449F02;
  transition: all 0.3s ease-in-out;
}
.btn.btn-primary{
  background-color: #C29F60;
  color: #FBFBFD;
  border: 1px solid #C29F60;
  transition: all 0.3s ease-in-out;
}
.btn.btn-secondary{
  background-color: #3F9A97;
  color: #FBFBFD;
  border: 1px solid #3F9A97;
  transition: all 0.3s ease-in-out;
}
.btn.btn-secondary:hover{
  background-color: #FBFBFD;
  color: #80BD65;
  border: 1px solid #80BD65;
  transition: all 0.3s ease-in-out;
}

.btn.img-btn{
  padding: 0px;
}
.btn.img-btn img{
  max-height: 35px;
}
.btn-group{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.btn-group > a.btn,
.btn-group > button.btn{
  margin-right: 10px;
}
.btn-group > a.btn:last-of-type,
.btn-group > button.btn:last-of-type{
  margin-right: 0px;
}
.RTL .btn-group > a.btn,
.RTL .btn-group > button.btn{
  margin-right: 0px;
  margin-left: 10px;
}
.RTL .btn-group > a.btn:last-of-type,
.RTL .btn-group > button.btn:last-of-type{
  margin-right: 0px;
  margin-left: 0px;
}

.fs-12{
  font-size: 12px!important;
}
.fs-14{
  font-size: 14px!important;
}
.fs-16{
  font-size: 16px!important;
}
.fs-18{
  font-size: 18px!important;
}
.fs-20{
  font-size: 20px!important;
}
.fs-22{
  font-size: 22px!important;
}

body .wrapper{
  width: 100%;
  max-width: 1650px;
  padding-left: 25px;
  padding-right: 25px;
  margin: 0 auto;
}

#scrollTop{
  background-color: #C29F60;
  color: #fff;
  position: fixed;
  bottom: 50px;
  right: 50px;
  padding: 12px 10px;
  font-size: 18px;
  border-radius: 5px;
  cursor: pointer;
  display: none;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 0px 0px #C29F60;
  transition: all 0.3s ease-in-out;
  z-index: 999;
}
.RTL #scrollTop{
  right: auto;
  left: 50px;
}
body.scrollTop #scrollTop{
  display: inline-flex;
}
#scrollTop:hover{
  transform: translateY(-5px);
  box-shadow: 0px 0px 5px 0px #399750;
  transition: all 0.3s ease-in-out;
}

.primary-heading{
  color: #fff;
  /* font-weight: 500; */
  font-size: 75px;
  margin: 12px 0;
}
.secondary-heading{
  color: #fff;
  font-weight: 700;
  font-size: 40px;
  margin: 0 0 0px;
}
.secondary-heading-alt{
  color: #fff;
  font-weight: 500;
  font-size: 22px;
  margin: 0 0 20px;
  text-transform: uppercase;
}
.sub-heading{
  color: #fff;
  font-weight: 400;
  font-size: 60px;
  margin: 0 0 20px;
}
.subtitle{
  color: #fff;
  font-weight: 500;
  font-size: 18px;
  margin: 0 0 20px;
}

.text-paragraph{
  color: #fff;
  /* font-weight: 400; */
  font-size: 18px;
  line-height: 1.5;
  margin: 0;
}

.hover-up{
  transition: all 0.3s ease-in-out;
}
.hover-up:hover{
  transform: translateY(-5px);
  transition: all 0.3s ease-in-out;
}

.animate-pulse {
	transform: scale(1);
	animation: pulse 2s infinite;
    opacity: 0.75;
}

.text-success{
  color: #3F9A97!important;
}
.text-danger{
  color: #D75555!important;
}

.loading{
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.loading img{
  max-width: 180px;
}
.loading p{
  color: #227F9F;
  font-weight: 400;
  font-size: 18px;
  margin: 20px 0 0;
}

.sigCanvas{
  max-width: 320px;
  width: 100%;
}

@keyframes pulse {
	0% {
		transform: scale(0.95);
        opacity: 0.75;
	}

	70% {
		transform: scale(1);
        opacity: 1;
	}

	100% {
		transform: scale(0.95);
        opacity: 0.75;
	}
}

.process-loading {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  align-items: center;
  justify-content: center;
  z-index: 999999;
  opacity: 0.8;
}
.process-loading .process-loading-spinner {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
}
.process-loading .process-loading-spinner div {
  transform-origin: 20px 20px;
  animation: process-loading-spinner 1.2s linear infinite;
}
.process-loading .process-loading-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 0px;
  left: 20px;
  width: 4px;
  height: 12px;
  border-radius: 20%;
  background: #fff;
}
.process-loading .process-loading-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.process-loading .process-loading-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.process-loading .process-loading-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.process-loading .process-loading-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.process-loading .process-loading-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.process-loading .process-loading-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.process-loading .process-loading-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.process-loading .process-loading-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.process-loading .process-loading-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.process-loading .process-loading-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.process-loading .process-loading-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.process-loading .process-loading-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes process-loading-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@media only screen and (max-width: 1680px) {
  body .wrapper {
    padding-left: 45px;
    padding-right: 45px;
}
}
@media only screen and (max-width: 1420px) {
  .btn {
    padding: 0px 25px;
    height: 40px;
  }
  .btn.img-btn img {
    max-height: 50px;
  }
  .primary-heading {
    font-size: 42px;
    margin: 0 0 30px;
  }
  .secondary-heading {
    font-size: 36px;
    margin: 0 0 16px;
  }
  .secondary-heading-alt {
    font-size: 18px;
    margin: 0 0 16px;
  }
  .subtitle {
    font-size: 16px;
    margin: 0 0 16px;
  }
  .text-paragraph {
    font-size: 16px;
  }
}
@media only screen and (max-width: 1300px) {
  body.RTL header .header-wrapper .header-left .header-navigation .navigation-menu-items li.menu-item{
    margin-right: 0;
    margin-left: 15px;
  }
  body.RTL header .header-wrapper .header-left .header-navigation .navigation-menu-items > li.menu-item:last-of-type {
    margin-right: 0;
    margin-left: 0;
  }
  body.RTL header .header-wrapper .btn {
    font-size: 14px;
    padding: 0px 15px;
  }
}

@media only screen and (max-width: 1200px){
  .secondary-heading {
    font-size: 32px;
    margin: 0 0 10px;
  }
  .secondary-heading-alt {
    font-size: 16px;
    margin: 0 0 10px;
  }
  .primary-heading {
    font-size: 38px;
    margin: 0 0 8px;
  }
  .sub-heading {
    font-size: 25px;
  }
  .btn.img-btn img {
    max-height: 40px;
  }
  #scrollTop{
    bottom: 35px;
    right: 35px;
  }
}
@media only screen and (max-width: 700px){
  body .wrapper {
    padding-left: 35px;
    padding-right: 35px;
  }
  #scrollTop{
    display: none!important;
  }
}
@media only screen and (max-width: 500px){
  .secondary-heading {
    font-size: 28px;
    margin: 0 0 10px;
  }  
  body .wrapper {
    padding-left: 25px;
    padding-right: 25px;
  }
}
@media only screen and (max-width: 350px){
  .btn-group.hero-btn-group > a.btn,
  .btn-group.hero-btn-group > button.btn{
    margin-right: 0px;
  }
}