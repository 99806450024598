/* Hero Section for admin */
section#agentAgencyHero {
    width: 100%;
    padding: 200px 0px 60px;
    background-image: url(../images/login-hero-b.png);
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

body.RTL section#agentAgencyHero {
    background-image: url(../images/login-hero-b.png);
}

section#agentAgencyHero .agentAgencyHero-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 3;
}

section#agentAgencyHero .agentAgencyHero-wrapper .section-left {
    position: relative;
    max-width: 30%;
    width: 100%;
    text-align: center;
}

section#agentAgencyHero .agentAgencyHero-wrapper .section-left p {
    max-width: 490px;
    margin-bottom: 0px;
}

section#agentAgencyHero .agentAgencyHero-wrapper .section-right {
    position: relative;
    text-align: center;
    max-width: 55%;
    width: 100%;
}

/* Authentication */
.auth-form-group-custom {
    position: relative;
}

.auth-form-group-custom .form-control {
    height: 55px;
    padding-top: 4px;
    padding-left: 60px;
    width: 100%;
}

.auth-form-group-custom label {
    position: absolute;
    top: 7px;
    left: 60px;
}

.auth-form-group-custom .auti-custom-input-icon {
    position: absolute;
    top: 0;
    left: 0px;
    font-size: 24px;
    background-color: #C29F60;
    padding: 14px 14px;
}

.btn-login {
    font-size: 16px;
    padding: 0px 32px;
    border-radius: 0;
    display: inline-flex;
    align-items: center;
    outline: none;
    box-shadow: none;
    text-decoration: none;
    font-weight: 600;
    transition: all 0.3s ease-in-out;
    height: 50px;
    cursor: pointer;
    background: #000;
    width: 100%;
    border: 1px solid #000;
    color: #fff;
    text-align: center;
    justify-content: center;
}

.btn-login.main {
    background: #C29F60;
    border: 1px solid #C29F60;
}

.invalid-feedback {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #ff7991;
}

.alert.alert-danger {
    color: #ff7991;
}

.login-links {
    text-decoration: none;
    color: #C29F60;
}

/* Dashboard */
.dashboard-header-wrapper {
    width: 100%;
    max-width: 1650px;
    margin: 20px auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dashboard-wrapper {
    width: 100%;
    max-width: 1650px;
    margin: 10px auto;
}

.payment-form-container {
    background-color: #F2E7D5;
    padding: 20px;
    border-radius: 20px;
}

/* dashboard end */

/* tabs */
.tabs {
    display: flex;
}

.tabs .button {
    padding: 10px 20px;
    border: none;
    background-color: #f0e4d7;
    cursor: pointer;
    background: #F2E7D5 0% 0% no-repeat padding-box;
    box-shadow: 3px 3px 6px #00000029;
    border-radius: 18px 18px 0px 0px;
    font-size: 16px;
    font-weight: 500;
    text-decoration: none;
    color: #000;
}

.tabs .button.active {
    background-color: #d4c3a0;
}

.tabs .button:focus,
.tabs .button:hover {
    background-color: #d4c3a0;
}

.tab-content {
    padding: 20px;
    border: 1px solid #ddd;
    background-color: #fff;
    border-radius: 5px;
}

/* tabs end */

/* table */

table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    font-size: 16px;

    /* background-color: #f8f8f8; */
    /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); */
}

th,
td {
    padding: 12px 15px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

th {
    color: #333;
    font-weight: bold;
    background-color: #F2E7D5;
}

tr {
    transition: background-color 0.3s;
    background-color: #f1f1f1;
}

tr:hover {
    background-color: #f1f1f1;
}

/* Zebra striping for rows */
tr:nth-child(even) {
    background-color: #fafafa;
}

.table-container {
    background-color: #F2E7D5;
    padding: 0px 20px 20px 20px;
    border-radius: 20px;
    overflow-x: auto;
    width: 100%;
}

.table-container .badge-soft-danger {
    background-color: rgba(255, 61, 96, .18);
    color: #ff3d60;
    padding: 1px 3px;
    border-radius: 5px;
}

.table-container .badge-soft-success {
    background-color: rgba(28, 187, 140, .18);
    color: #1cbb8c;
    padding: 1px 3px;
    border-radius: 5px;
}

.table-container .badge-soft-info {
    background-color: rgba(74, 163, 255, .18);
    color: #4aa3ff;
    padding: 1px 3px;
    border-radius: 5px;
}
.table-container .badge-soft-warning {
    background-color: rgba(252,185,44,.18);
    color: #fcb92c;
    padding: 1px 3px;
    border-radius: 5px;
}
.table-container .badge-soft-secondary {
    background-color: rgba(116,120,141,.18);
    color: #74788d;
    padding: 1px 3px;
    border-radius: 5px;
}

/* table end */

/* pagination */
.pagination-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.pagination {
    background-color: transparent;
    border-radius: 5px;
    padding: 5px 10px;
    display: inline-flex;
    list-style: none;
    margin: 0;
}

.pagination-container nav {
    border: 1px solid #dbd4d4;
    border-radius: 10px;
}

.pagination .page-item {
    margin: 0 5px;
}

.pagination .page-item .page-link {
    color: #b8c3d1;
    text-decoration: none;
    padding: 5px 12px;
    border-radius: 5px;
    background-color: transparent;
    border: 1px solid transparent;
    display: block;
    transition: all 0.3s ease;
}

.pagination .page-item .page-link:hover {
    background-color: #d4c3a0;
    color: #ffffff;
}

.pagination .page-item.disabled .page-link {
    color: #7a869a;
    pointer-events: none;
}

.pagination .page-item.active .page-link {
    background-color: #C89D55;
    color: #ffffff;
    border: 1px solid #C89D55;
}

.pagination .page-item:first-child .page-link,
.pagination .page-item:last-child .page-link {
    border-radius: 5px;
}

/* pagination end */

/* filters */
.filter-container {
    display: flex;
    gap: 20px;
}

.admin-btn-search {
    background: transparent;
    border: none;
    font-size: 15px;
}

.admin-search,
.admin-select {
    background-color: #F2E7D5;
    border: none;
}

/* filters end */

/* create order modal */
.create-order-container {
    display: flex;
    gap: 20px;
}

.create-order-container .section-left {
    width: 100%;
    max-width: 55%;
    background-color: #F2E7D5;
    padding: 30px 20px;
    border-radius: 20px;
}

.create-order-container .section-left .card-title {
    font-size: 22px;
    margin: 0px;
    font-weight: 500;
}

.create-order-container .section-left .card-title-desc {
    font-size: 16px;
    margin: 0px;
    font-weight: 400;
}

.create-order-container .form-control {
    display: block;
    padding: 6px 12px;
    width: 100%;
    border: none;
}

.create-order-container .custom-number-input {
    display: block;
    padding: 6px 10px;
    width: 100%;
    max-width: 80px;
    color: #fff;
    background-color: #C29F60;
    border: none;
    border-radius: 4px;
}

.create-order-container .form-select {
    display: block;
    padding: 5px 20px;
    background-color: #fff;
    border: none;
    width: 100%;
    max-width: 200px;
}

.create-order-container .section-right h5.title {
    font-size: 24px;
    margin: 0px;
    color: #C29F60;
    font-weight: 500;
}

.create-order-container .section-right p.title-desc {
    font-size: 16px;
    margin: 0px;
    font-weight: 400;
}

.create-order-container .section-right .info-heading {
    font-size: 18px;
    margin: 0px;
    font-weight: 500;
}

.create-order-container .section-right .info-desc {
    font-size: 12px;
}

.dropzone {
    border: 2px dashed #79859c;
    border-radius: 6px;
    min-height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dropzone .dz-message {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #79859c;
}

.dropzone .dz-message .ri-upload-cloud-2-line {
    font-size: 35px;
}

.dropzone-previews {
    background: #f5f5f5;
    padding: 10px;
    border-radius: 10px;
}

.dropzone-previews a {
    text-decoration: none;
    color: #C29F60;
}

.download-sample-sheet a {
    text-decoration: none;
    color: #C29F60;
}

.dropzone-previews .clear-btn {
    background: none;
    border: 0;
    box-shadow: none;
    color: #e15555;
    outline: none;
}

#customQuantity {
    padding: 6px 12px;
    border: none;
}
.single-order-form{
    background: #F4EEE4 0% 0% no-repeat padding-box;
    border-radius: 20px;
    padding: 20px;
}
.single-order-form:nth-child(even){
    background: #E8DCC7 0% 0% no-repeat padding-box; 
}

/* create order modal ends */

/* dropdown */
.umrah-dropdown {
    position: relative;
    display: inline-block;
}

.umrah-dropdown-toggle {
    background-color: #c09a58;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    font-size: 1em;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

.umrah-dropdown-content {
    /* display: none; */
    position: absolute;
    background-color: #fff;
    min-width: 160px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
    border: 1px solid #ddd;
    border-radius: 5px;
    z-index: 1;
    margin-top: 10px;
}

.umrah-dropdown-content button {
    background-color: #f8f8f8;
    color: #333;
    padding: 10px;
    width: 100%;
    text-align: left;
    border: none;
    cursor: pointer;
    display: block;
    font-size: 0.9em;
}

.umrah-dropdown-content button:hover {
    background-color: #ddd;
}

.umrah-show {
    display: block;
}

/* dropdoen end */

/* agenty top badge */
.agency-badge {
    display: flex;
    align-items: center;
    background-color: #2e3236;
    color: white;
    padding: 8px 16px;
    border-radius: 30px;
    font-size: 1em;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    max-width: fit-content;
}

.agency-icon {
    background-color: #c09a58;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
}
body.RTL .agency-icon{
    margin-right: 0px;
    margin-left: 8px;
}

.agency-icon img {
    width: 24px;
    height: 24px;
    border-radius: 50%;
}

.agency-name {
    white-space: nowrap;
    font-weight: 500;
}

/* agenty top badge end */

/* Report Analysis */
.top-cards-container {
    display: flex;
    gap: 20px;
}

.single-card-container {
    width: 100%;
    max-width: 24%;
}

.number-card {
    margin-bottom: 24px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
    word-wrap: break-word;
    border-radius: 10px;
    padding: 10px 20px;
}

.graph-card {
    margin-bottom: 24px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
    word-wrap: break-word;
    border-radius: 10px;
    padding: 10px 20px;
}

.bottom-cards-container {
    display: flex;
    gap: 20px;
}

.graph-container {
    width: 100%;
    max-width: 65%;
}

.number-cards-container {
    width: 100%;
    max-width: 34%;
}

.stats-number-container {
    display: grid;
    grid-template-columns: 48% 48%;
}

.graph-card .mdi-circle.text-primary {
    background-color: #C29F60;
    width: 8px;
    height: 8px;
    display: inline-block;
    border-radius: 50px;
}

.graph-card .mdi-circle.text-danger {
    background-color: #ff3d60;
    width: 8px;
    height: 8px;
    display: inline-block;
    border-radius: 50px;
}

.graph-card .mdi-circle.text-warning {
    background-color: #fcb92c;
    width: 8px;
    height: 8px;
    display: inline-block;
    border-radius: 50px;
}

.graph-card .mdi-circle.text-success {
    background-color: #C29F60;
    width: 8px;
    height: 8px;
    display: inline-block;
    border-radius: 50px;
}

/* Report Analysis end */

/* My profile start */
.profile-container {
    background-color: #F2E7D5;
    padding: 40px 20px;
    border-radius: 20px;
}

.profile-container .profile-picture img {
    width: 150px;
    height: 150px;
    border-radius: 100%;
    border: 3px solid #c09a58;
}

.profile-container .info-row {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    gap: 30px;
}

.profile-container .info-row .info-col {
    width: 100%;
    max-width: 48%;
}

/* My profile ends */


/* Media Queries */
@media only screen and (max-width: 1420px) {
    section#agentAgencyHero .agentAgencyHero-wrapper .section-left img {
        width: 100px;
    }

    section#agentAgencyHero .agentAgencyHero-wrapper .section-left p {
        max-width: 100%;
    }

    section#agentAgencyHero .agentAgencyHero-wrapper .section-left .sub-heading {
        font-size: 30px;
    }
}

@media only screen and (max-width: 1150px) {
    .filter-btn-container {
        flex-wrap: wrap;
        flex-direction: column-reverse;
        align-items: start;
    }

    .umrah-dropdown {
        margin-bottom: 15px;
    }

    .top-cards-container {
        flex-wrap: wrap;
    }

    .single-card-container {
        max-width: 48%;
    }

    .bottom-cards-container {
        flex-wrap: wrap;
    }

    .graph-container {
        max-width: 100%;
    }

    .number-cards-container {
        max-width: 100%;
    }
}

@media only screen and (max-width: 900px) {
    section#agentAgencyHero .agentAgencyHero-wrapper .section-left {
        max-width: 60%;
    }

    .filter-container {
        flex-wrap: wrap;
    }

    .filter-container div {
        margin-bottom: 0px !important;
    }

    .table-container {
        margin-top: 10px;
    }

    .create-order-container .section-left {
        max-width: 100%;
    }

    .create-order-container .section-right {
        display: none;
    }
}

@media only screen and (max-width: 768px) {
    .tabs {
        justify-content: space-between;
    }

    .tabs .button {
        padding: 10px;
        font-size: 0.9em;
    }
}

@media only screen and (max-width: 640px) {
    .dashboard-header-wrapper .section-left img {
        width: 150px;
    }

    section#agentAgencyHero .agentAgencyHero-wrapper .section-left {
        max-width: 100%;
        text-align: center !important;
    }

    section#agentAgencyHero .agentAgencyHero-wrapper .section-left .btn-group {
        justify-content: center;
    }

    section#agentAgencyHero .agentAgencyHero-wrapper .section-right {
        max-width: 0%;
    }

    .tabs {
        flex-direction: column;
        gap: 8px;
        margin: 0px 8px;
    }

    .tabs .button {
        width: 100%;
        padding: 12px 10px;
        font-size: 1em;
    }

    .tab-content {
        margin-top: 15px;
    }

    .pagination-container {
        flex-wrap: wrap;
        gap: 10px;
        justify-content: center;
    }

    .single-card-container {
        max-width: 100%;
    }
}

@media only screen and (max-width: 450px) {
    .pagination .page-item .page-link {
        padding: 1px 6px;
    }

    .dashboard-header-wrapper .section-left img {
        width: 100px;
    }

    .agency-badge {
        font-size: 12px;
        padding: 5px 10px;
    }
}