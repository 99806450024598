.modal{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99999;
}
.modal .modal-overlay{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    z-index: 10000;
    background-color: #000;
}
.modal .modal-container{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 10001;
    max-width: 600px;
    width: 100%;
    padding: 25px;
}
.modal .modal-container.modal-container-small{
    max-width: 400px;
}
.modal .modal-container.modal-container-xLarge{
    max-width: 1200px;
}
.modal .modal-container .modal-content{
    padding: 30px 25px;
    background-color: #fff;
    border-radius: 5px;
}
.modal .modal-container .modal-content .modal-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    position: relative;
}
.modal .modal-container .modal-content .modal-header .modal-title{
    font-weight: 500;
    font-size: 20px;
    margin: 0;
    color: #C29F60;
}
.modal .modal-container .modal-content .modal-header .modal-close{
    font-weight: 600;
    font-size: 22px;
    border: none;
    outline: none;
    box-shadow: none;
    background: none;
    padding: 0;
    margin: 0 0 0 15px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.RTL .modal .modal-container .modal-content .modal-header .modal-close{
    margin: 0 15px 0 0;
}
.modal .modal-container .modal-content .modal-header .modal-close.single{
    top: 0;
    right: 0;
    position: absolute;
}
.modal .modal-container .modal-content .modal-body{
    max-height: 80vh;
    overflow: auto;
}


@media(max-width: 600px) {
    .modal .modal-container {
        padding: 15px;
    }
    .modal .modal-container .modal-content{
        padding: 20px 15px;
    }
}