section#faqHero{
    width: 100%;
    padding: 200px 0px 60px;
    background-image: url(../images/hero.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
body.RTL section#faqHero{
    background-image: url(../images/hero-AR.jpg);
}
/* section#faqHero::before{
    content: '';
    background-image: url(../images/help-center-background.png);
    display: inline-block;
    position: absolute;
    height: 100%;
    width: 55%;
    top: 0;
    right: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top left;
    z-index: 0;
} */
/* .RTL section#faqHero::before{
    background-image: url(../images/help-center-background-RTL.png);
    top: 0;
    right: auto;
    left: 0;
    background-position: top right;
} */
section#faqHero .faqHero-wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
section#faqHero .faqHero-wrapper .section-left{
    position: relative;
    max-width: 60%;
    width: 100%;
}
section#faqHero .faqHero-wrapper .section-left p{
    max-width: 490px;
    margin-bottom: 0px;
}
section#faqHero .faqHero-wrapper .section-left .btn-group{
    margin-top: 30px;
}
section#faqHero .faqHero-wrapper .section-right{
    position: relative;
    text-align: center;
    max-width: 55%;
    width: 100%;
}
section#faqHero .faqHero-wrapper .section-right img{  
    max-width: 650px;
    width: 100%;
}
section#faqBody{
    padding: 100px 0px;
    background-image: url(../images/hoetawkeelWorksBG.png);
    background-size: contain;
    background-repeat: repeat-y;
}
section#faqBody.umrah-al-badal-faq{
    background-size: cover;
    background-repeat: no-repeat;
}
section#faqBody .faqBody-wrapper .faq-navigation{
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 60px;
}
section#faqBody .faqBody-wrapper .secondary-heading{
    margin-bottom: 50px;
}
section#faqBody .faqBody-wrapper .faq-listing{
    max-width: 800px;
    width: 100%;
    margin: 0 auto 20px;
}
section#faqBody .faqBody-wrapper .faq-listing .single-faq{
    width: 100%;
    border: 1px solid #707070;;
    border-radius: 20px;
    padding: 20px;
    margin-bottom: 30px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    background: #62626280 0% 0% no-repeat padding-box;
}
section#faqBody .faqBody-wrapper .faq-listing .single-faq.open{
    border: 2px solid #707070;
    transition: all 0.3s ease-in-out;
}
section#faqBody .faqBody-wrapper .faq-listing .single-faq .faq-question{
    color: #CECECE;
    font-weight: 500;
    font-size: 22px;
    margin: 0;
    transition: all 0.3s ease-in-out;
}
section#faqBody .faqBody-wrapper .faq-listing .single-faq.open .faq-question{
    color: #CECECE;
    margin: 0 0 20px;
    transition: all 0.3s ease-in-out;
}
section#faqBody .faqBody-wrapper .faq-listing .single-faq .faq-answer{
    max-height: 0;
    transition: max-height 0.15s ease-out;
    overflow: hidden;
}
section#faqBody .faqBody-wrapper .faq-listing .single-faq.open .faq-answer{
    max-height: 800px;
    transition: max-height 0.25s ease-in;
}
section#faqBody .faqBody-wrapper .faq-listing .single-faq .faq-answer p{
    color: #CECECE;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.5;
    margin: 0;
}

@media only screen and (max-width: 1680px){
    section#faqHero .faqHero-wrapper .section-right img {
        max-width: 500px;
    }
}
@media only screen and (max-width: 1460px){
    section#faqHero .faqHero-wrapper .section-right img {
        max-width: 500px;
    }
}
@media only screen and (max-width: 1200px){
    section#faqBody .faqBody-wrapper .faq-navigation {
        max-width: 620px;
        width: 100%;
        flex-wrap: wrap;
        margin: 0 auto 60px;
    }
}
@media only screen and (max-width: 1150px){
    section#faqHero .faqHero-wrapper .section-left .btn-group {
        margin-top: 50px;
    }
    section#faqHero .faqHero-wrapper .section-right img {
        max-width: 400px;
    }
}
@media only screen and (max-width: 768px){
    section#faqHero .faqHero-wrapper .section-right {
        display: none;
    }
    section#faqHero .faqHero-wrapper .section-left p,
    section#faqHero .faqHero-wrapper .section-left {
        max-width: 100%;
        text-align: center;
        color: #fff;
    }
    section#faqHero .faqHero-wrapper .section-left .btn-group {
        margin-top: 25px;
        justify-content: center;
    }
    section#faqHero::before {
        width: 45%;
    }
    section#faqBody .faqBody-wrapper .faq-listing .single-faq .faq-question {
        font-size: 18px;
    }
    section#faqBody .faqBody-wrapper .faq-listing .single-faq .faq-answer p {
        color: #CECECE;
        font-weight: 500;
        font-size: 16px;
    }
    section#faqBody .faqBody-wrapper .faq-navigation .btn.btn-primary {
        padding: 8px 15px;
        height: auto;
        font-size: 14px;
    }
}