section#helpCenterHome {
    width: 100%;
    padding: 200px 0px 60px;
    background-image: url(../images/hero.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
body.RTL section#helpCenterHome{
    background-image: url(../images/hero-AR.jpg);
}

/* section#helpCenterHome::before {
    content: '';
    background-image: url(../images/help-center-background.png);
    display: inline-block;
    position: absolute;
    height: 100%;
    width: 55%;
    top: 0;
    right: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top left;
    z-index: 0;
}

.RTL section#helpCenterHome::before {
    background-image: url(../images/help-center-background-RTL.png);
    top: 0;
    right: auto;
    left: 0;
    background-position: top right;
} */

section#helpCenterHome .helpCenterHome-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 2;
}

section#helpCenterHome .helpCenterHome-wrapper .section-left {
    position: relative;
    max-width: 45%;
    width: 100%;
}

section#helpCenterHome .helpCenterHome-wrapper .section-left p {
    max-width: 490px;
    margin-bottom: 30px;
}

section#helpCenterHome .helpCenterHome-wrapper .section-right {
    position: relative;
    text-align: center;
    max-width: 55%;
    width: 100%;
}

section#helpCenterHome .helpCenterHome-wrapper .section-right img {
    max-width: 100%;
    width: 100%;
}


section#helpCenter {
    width: 100%;
    padding: 60px 0px;
    background-image: url(../images/hoetawkeelWorksBG.png);
    background-size: contain;
    background-repeat: repeat-y;
}

section#helpCenter .helpCenter-wrapper {}

section#helpCenter .helpCenter-wrapper .helpCenter-container {
    /* background-color: #FBFBFD; */
    width: 100%;
    border-radius: 20px;
    overflow: hidden;
    position: relative;
}

section#helpCenter .helpCenter-wrapper .helpCenter-container .helpCenter-header {
    background-color: #C29E60;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 35px;
}

section#helpCenter .helpCenter-wrapper .helpCenter-container .helpCenter-header .header-left {
    max-width: 40%;
    width: 100%;
    display: flex;
    align-items: center;
}

section#helpCenter .helpCenter-wrapper .helpCenter-container .helpCenter-header .header-left button {
    margin-right: 20px;
    background: none;
    border: 0px;
    cursor: pointer;
    max-width: 35px;
    width: 100%;
    max-height: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 25px;
}

.RTL section#helpCenter .helpCenter-wrapper .helpCenter-container .helpCenter-header .header-left button {
    margin-right: 0px;
    margin-left: 20px;
}

section#helpCenter .helpCenter-wrapper .helpCenter-container .helpCenter-header .header-left button span {
    display: inline-block;
    width: 100%;
    background-color: #fff;
    height: 3px;
    border-radius: 5px;
    transition: all 0.3s ease-in-out;
}

section#helpCenter .helpCenter-wrapper .helpCenter-container .helpCenter-header .header-left button>span:last-of-type {
    max-width: 18px;
}

section#helpCenter .helpCenter-wrapper .helpCenter-container .helpCenter-header .header-left button:hover>span:last-of-type {
    transition: all 0.3s ease-in-out;
    max-width: 100%;
}

section#helpCenter .helpCenter-wrapper .helpCenter-container .helpCenter-header .header-left h4 {
    color: #fff;
    font-size: 26px;
    margin: 0;
    font-weight: 500;
}

section#helpCenter .helpCenter-wrapper .helpCenter-container .helpCenter-sidebar {
    position: absolute;
    top: 90px;
    left: -1000px;
    background: #fff;
    height: calc(100% - 100px);
    transition: all 0.3s ease-in-out;
}

.RTL section#helpCenter .helpCenter-wrapper .helpCenter-container .helpCenter-sidebar {
    left: auto;
    right: -1000px;
}

section#helpCenter .helpCenter-wrapper .helpCenter-container.show-sidebar .helpCenter-sidebar {
    left: 0;
    transition: all 0.3s ease-in-out;
}

.RTL section#helpCenter .helpCenter-wrapper .helpCenter-container.show-sidebar .helpCenter-sidebar {
    left: auto;
    right: 0px;
    transition: all 0.3s ease-in-out;
}

section#helpCenter .helpCenter-wrapper .helpCenter-container .helpCenter-sidebar ul {
    padding: 50px;
    margin: 0;
    list-style: none;
}

section#helpCenter .helpCenter-wrapper .helpCenter-container .helpCenter-sidebar ul li {
    margin: 10px 0px;
}

section#helpCenter .helpCenter-wrapper .helpCenter-container .helpCenter-sidebar ul>li>a {
    color: #828282;
    font-size: 18px;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
    display: flex;
    align-items: center;
}

section#helpCenter .helpCenter-wrapper .helpCenter-container .helpCenter-sidebar ul>li>a::before {
    content: "";
    display: inline-block;
    height: 5px;
    width: 5px;
    background-color: #828282;
    border-radius: 10px;
    margin-right: 10px;
    transition: all 0.3s ease-in-out;
}

.RTL section#helpCenter .helpCenter-wrapper .helpCenter-container .helpCenter-sidebar ul>li>a::before {
    margin-right: 0px;
    margin-left: 10px;
}

section#helpCenter .helpCenter-wrapper .helpCenter-container .helpCenter-sidebar ul>li>a:hover {
    color: #C29E60;
    transition: all 0.3s ease-in-out;
}

section#helpCenter .helpCenter-wrapper .helpCenter-container .helpCenter-sidebar ul>li>a:hover::before {
    background-color: #3f9a97;
    transition: all 0.3s ease-in-out;
}

section#helpCenter .helpCenter-wrapper .helpCenter-container .helpCenter-sidebar ul>li>ol {
    list-style: none;
}

section#helpCenter .helpCenter-wrapper .helpCenter-container .helpCenter-sidebar ul>li>ol li a {
    color: #828282;
    font-size: 16px;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
    display: flex;
    align-items: center;
}

section#helpCenter .helpCenter-wrapper .helpCenter-container .helpCenter-sidebar ul>li>ol li a::before {
    content: "";
    display: inline-block;
    height: 3px;
    width: 6px;
    background-color: #828282;
    border-radius: 10px;
    margin-right: 10px;
    transition: all 0.3s ease-in-out;
}

.RTL section#helpCenter .helpCenter-wrapper .helpCenter-container .helpCenter-sidebar ul>li>ol li a::before {
    margin-right: 0px;
    margin-left: 10px;
}

section#helpCenter .helpCenter-wrapper .helpCenter-container .helpCenter-sidebar ul>li>ol li a:hover {
    color: #C29E60;
    transition: all 0.3s ease-in-out;
}

section#helpCenter .helpCenter-wrapper .helpCenter-container .helpCenter-sidebar ul>li>ol li a:hover::before {
    background-color: #3f9a97;
    transition: all 0.3s ease-in-out;
}

section#helpCenter .helpCenter-wrapper .helpCenter-container .helpCenter-content {
    padding: 65px 0px;
    display: flex;
    overflow: auto;
    background: #62626280 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
}

section#helpCenter .helpCenter-wrapper .helpCenter-container .helpCenter-content::-webkit-scrollbar {
    display: none;
}

section#helpCenter .helpCenter-wrapper .helpCenter-container .helpCenter-content {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

section#helpCenter .helpCenter-wrapper .helpCenter-container .helpCenter-content .helpCenter-section {
    min-width: 100%;
    padding: 0px 30px;
}

section#helpCenter .helpCenter-wrapper .helpCenter-container .helpCenter-content .helpCenter-section .section-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 50px;
}

section#helpCenter .helpCenter-wrapper .helpCenter-container .helpCenter-content .helpCenter-section .section-row .section-column {
    flex: 1;
    width: 100%;
}

section#helpCenter .helpCenter-wrapper .helpCenter-container .helpCenter-content .helpCenter-section .section-row .section-column.contains-image img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    width: 100%;
}

section#helpCenter .helpCenter-wrapper .helpCenter-container .helpCenter-content .helpCenter-section .section-primary-heading {
    color: #C29E60;
    font-size: 32px;
    margin: 0 0 15px;
    font-weight: 500;
}

section#helpCenter .helpCenter-wrapper .helpCenter-container .helpCenter-content .helpCenter-section .section-secondary-heading {
    color: #C29E60;
    font-size: 26px;
    margin: 0 0 15px;
    font-weight: 500;
}

section#helpCenter .helpCenter-wrapper .helpCenter-container .helpCenter-content .helpCenter-section .section-sub-heading {
    color: #C29E60;
    font-size: 20px;
    margin: 0 0 15px;
    font-weight: 500;
}

section#helpCenter .helpCenter-wrapper .helpCenter-container .helpCenter-content .helpCenter-section .section-paragraph {
    color: #CECECE;
    font-size: 18px;
    margin: 0 0 30px;
    font-weight: 500;
}

section#helpCenter .helpCenter-wrapper .helpCenter-container .helpCenter-content .helpCenter-section .section-paragraph a {
    color: #C29E60;
    word-break: break-all;
}

section#helpCenter .helpCenter-wrapper .helpCenter-container .helpCenter-content .helpCenter-section ul,
section#helpCenter .helpCenter-wrapper .helpCenter-container .helpCenter-content .helpCenter-section ol {
    list-style-position: inside;
    margin: 0 0 20px;
    padding: 0;
}

section#helpCenter .helpCenter-wrapper .helpCenter-container .helpCenter-content .helpCenter-section ul li,
section#helpCenter .helpCenter-wrapper .helpCenter-container .helpCenter-content .helpCenter-section ol li {
    color: #CECECE;
    font-size: 18px;
    margin: 0 0 20px;
}

section#helpCenter .helpCenter-wrapper .helpCenter-container .helpCenter-content .helpCenter-section ul li::marker,
section#helpCenter .helpCenter-wrapper .helpCenter-container .helpCenter-content .helpCenter-section ol li::marker {
    color: #454545;
    font-weight: 500;
}

section#helpCenter .helpCenter-wrapper .helpCenter-container .helpCenter-content .helpCenter-section ul li strong,
section#helpCenter .helpCenter-wrapper .helpCenter-container .helpCenter-content .helpCenter-section ol li strong {
    font-weight: 500;
    color: #454545;
}

section#helpCenter .helpCenter-wrapper .helpCenter-container .helpCenter-content .helpCenter-section ul>li:last-of-type,
section#helpCenter .helpCenter-wrapper .helpCenter-container .helpCenter-content .helpCenter-section ol>li:last-of-type {
    margin: 0 0 0;
}

section#helpCenter .helpCenter-wrapper .helpCenter-container .helpCenter-loading {
    background-color: #C29E60;
    height: 5px;
}


@media only screen and (max-width: 1420px) {
    section#helpCenter .helpCenter-wrapper .helpCenter-container .helpCenter-content .helpCenter-section .section-primary-heading {
        font-size: 28px;
    }

    section#helpCenter .helpCenter-wrapper .helpCenter-container .helpCenter-content .helpCenter-section .section-secondary-heading {
        font-size: 22px;
    }

    section#helpCenter .helpCenter-wrapper .helpCenter-container .helpCenter-content .helpCenter-section .section-sub-heading {
        font-size: 18px;
    }

    section#helpCenter .helpCenter-wrapper .helpCenter-container .helpCenter-content .helpCenter-section .section-paragraph {
        color: #CECECE;
        font-size: 16px;
    }

    section#helpCenter .helpCenter-wrapper .helpCenter-container .helpCenter-header .header-left h4 {
        font-size: 24px;
    }

    section#helpCenter .helpCenter-wrapper .helpCenter-container .helpCenter-header .header-left button {
        max-height: 20px;
        height: 20px;
    }

    section#helpCenter .helpCenter-wrapper .helpCenter-container .helpCenter-header .header-left button span {
        height: 2px;
    }
}

@media only screen and (max-width: 1050px) {
    section#helpCenter .helpCenter-wrapper .helpCenter-container .helpCenter-content .helpCenter-section .section-primary-heading {
        font-size: 24px;
    }

    section#helpCenter .helpCenter-wrapper .helpCenter-container .helpCenter-content .helpCenter-section .section-secondary-heading {
        font-size: 20px;
    }

    section#helpCenter .helpCenter-wrapper .helpCenter-container .helpCenter-header .header-left h4 {
        font-size: 20px;
    }

    section#helpCenter .helpCenter-wrapper .helpCenter-container .helpCenter-header .header-left button {
        max-width: 30px;
    }

    section#helpCenter .helpCenter-wrapper .helpCenter-container .helpCenter-header .header-left button>span:last-of-type {
        max-width: 14px;
    }

    section#helpCenter .helpCenter-wrapper .helpCenter-container .helpCenter-content .helpCenter-section .section-row {
        flex-direction: column;
    }
}

@media only screen and (max-width: 860px) {
    section#helpCenter .helpCenter-wrapper .helpCenter-container .helpCenter-header .header-left {
        max-width: 50%;
    }

    section#helpCenterHome .helpCenterHome-wrapper .section-right {
        display: none;
    }

    section#helpCenterHome .helpCenterHome-wrapper .section-left {
        width: 100%;
        max-width: 100%;
        text-align: center;
    }

    section#helpCenterHome .helpCenterHome-wrapper .section-left p {
        max-width: 100%;
        margin-bottom: 50px;
        color: #fff;
    }

    section#helpCenterHome .helpCenterHome-wrapper .section-left .btn-group {
        justify-content: center;
    }
}

@media only screen and (max-width: 640px) {
    section#helpCenter .helpCenter-wrapper .helpCenter-container .helpCenter-header .header-right a.btn {
        font-size: 0px;
    }

    section#helpCenter .helpCenter-wrapper .helpCenter-container .helpCenter-header .header-right a.btn svg {
        margin-right: 0px;
    }

    section#helpCenter .helpCenter-wrapper .helpCenter-container .helpCenter-header .header-left {
        max-width: 70%;
    }
}

@media only screen and (max-width: 500px) {
    section#helpCenter .helpCenter-wrapper .helpCenter-container .helpCenter-header .header-left h4 {
        font-size: 18px;
    }

    section#helpCenter .helpCenter-wrapper .helpCenter-container .helpCenter-header {
        padding: 20px 20px;
    }

    section#helpCenter .helpCenter-wrapper .helpCenter-container .helpCenter-content .helpCenter-section {
        padding: 0px 20px;
    }

    section#helpCenter .helpCenter-wrapper .helpCenter-container .helpCenter-content {
        padding: 35px 0px;
        overflow-y: auto;
        max-height: 800px;
    }

    section#helpCenter .helpCenter-wrapper .helpCenter-container .helpCenter-sidebar {
        width: 100%;
    }
}