section#home {
    width: 100%;
    padding: 200px 0px 60px;
    /* background-image: url(../images/hero.jpg); */
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

/* body.RTL section#home{
    background-image: url(../images/hero-AR.jpg);
} */
.image-fallback {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    z-index: 1;
}

.hidden {
    display: none !important;
}

.video-background {
    /* padding: 137px 0px 0px; */
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
}

.home-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
}

/* section#home::before{
    content: '';
    background-image: url(../images/main-background-02.png);
    display: inline-block;
    position: absolute;
    height: 100%;
    width: 55%;
    top: 0;
    right: 0;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top right;
    z-index: 0;
}
.RTL section#home::before{
    background-image: url(../images/main-background-02-RTL.png);
    background-position: top left;
    right: auto;
    left: 0;
} */
section#home .play-button {
    margin-top: 100px;
    position: relative;
    max-width: 80px;
}

section#home .play-button button {
    border-radius: 50%;
    display: inline-flex;
    height: 80px;
    width: 80px;
    align-items: center;
    justify-content: center;
    border: 0px;
    color: #fff;
    font-size: 34px;
    cursor: pointer;
    z-index: 1;
    transition: all 0.3s ease-in-out;
    background: rgb(109, 179, 114);
    background: -moz-linear-gradient(165deg, rgba(109, 179, 114, 1) 0%, rgba(8, 126, 191, 1) 100%);
    background: -webkit-linear-gradient(165deg, rgba(109, 179, 114, 1) 0%, rgba(8, 126, 191, 1) 100%);
    background: linear-gradient(165deg, rgba(109, 179, 114, 1) 0%, rgba(8, 126, 191, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#6db372", endColorstr="#087ebf", GradientType=1);
    position: relative;
}

section#home .play-button button span {
    opacity: 0;
    border-radius: 50%;
    display: inline-flex;
    height: 80px;
    width: 80px;
    align-items: center;
    justify-content: center;
    border: 0px;
    color: #fff;
    font-size: 34px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    background: rgb(109, 179, 114);
    background: -moz-linear-gradient(30deg, rgba(109, 179, 114, 1) 0%, rgba(8, 126, 191, 1) 100%);
    background: -webkit-linear-gradient(30deg, rgba(109, 179, 114, 1) 0%, rgba(8, 126, 191, 1) 100%);
    background: linear-gradient(30deg, rgba(109, 179, 114, 1) 0%, rgba(8, 126, 191, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#6db372", endColorstr="#087ebf", GradientType=1);
    transition: all 0.3s ease-in-out;
    position: absolute;
    z-index: 2;
}

section#home .play-button button:hover span {
    opacity: 1;
    transition: all 0.3s ease-in-out;
}

section#home .play-button button i {
    position: relative;
    z-index: 3;
}

section#home .play-button::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    border-radius: 50%;
    display: inline-flex;
    height: 80px;
    width: 80px;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 0 0 rgba(109, 179, 114, 1);
    transform: scale(1);
    opacity: 1;
    animation: pulse_01 3s infinite;
}

section#home .play-button::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    border-radius: 50%;
    display: inline-flex;
    height: 80px;
    width: 80px;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 0 0 rgba(109, 179, 114, 1);
    transform: scale(1);
    opacity: 1;
    animation: pulse_02 4s infinite;
}

@keyframes pulse_01 {
    0% {
        transform: scale(1);
        box-shadow: 0 0 0 0 rgba(109, 179, 114, 0.7);
        background-color: rgba(109, 179, 114, 0.7);
        opacity: 0.7;
    }

    70% {
        transform: scale(1.5);
        box-shadow: 0 0 0 10px rgba(109, 179, 114, 0);
        background-color: rgba(109, 179, 114, 0);
        opacity: 0;
    }

    100% {
        transform: scale(1);
        box-shadow: 0 0 0 0 rgba(109, 179, 114, 0);
        background-color: rgba(109, 179, 114, 0);
        opacity: 0.7;
    }
}

@keyframes pulse_02 {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(109, 179, 114, 0.7);
        background-color: rgba(109, 179, 114, 0.7);
        opacity: 0.7;
    }

    50% {
        transform: scale(1.3);
        box-shadow: 0 0 0 10px rgba(109, 179, 114, 0);
        background-color: rgba(109, 179, 114, 0);
        opacity: 0;
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(109, 179, 114, 0);
        background-color: rgba(109, 179, 114, 0);
        opacity: 0.7;
    }
}

/* .btn.btn-primary-home {
    background-color: #C29F60;
    color: #FBFBFD;
    border: 1px solid #C29F60;
    transition: all 0.3s ease-in-out;
} */
section#home .hero-video .video-wrapper {
    position: fixed;
    display: inline-block;
    top: -100vh;
    bottom: 100vh;
    left: 0;
    right: 0;
    background: #000;
    opacity: 0;
    z-index: 999;
    transition: all 0.3s ease-in-out;
}

section#home .hero-video .video-wrapper.show {
    top: 0;
    bottom: 0;
    opacity: 1;
    transition: all 0.3s ease-in-out;
}

section#home .hero-video .video-wrapper .video {
    position: absolute;
    top: 50%;
    left: 50%;
    padding: 50px;
    width: 100%;
    transform: translate(-50%, -50%);
    z-index: 1;
}

section#home .hero-video .video-wrapper .video video {
    max-height: 90vh;
    width: 100%;
}

section#home .hero-video .video-wrapper .btn-close {
    background: none;
    position: absolute;
    top: 30px;
    right: 30px;
    z-index: 2;
    color: #fff;
    border: 0px;
    outline: none;
    cursor: pointer;
    font-size: 30px;
}

section#home .home-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 2;
}

section#home .home-wrapper .section-left {
    position: relative;
    max-width: 48%;
    width: 100%;
}

section#home .home-wrapper .section-right {
    position: relative;
    text-align: center;
    max-width: 55%;
    width: 100%;
}

section#home .home-wrapper .section-right img {
    max-width: 800px;
    width: 100%;
    margin-top: -150px;
}

/* About */
section#about {
    width: 100%;
    background-image: url(../images/about.png);
    background-size: cover;
    background-repeat: no-repeat;
    padding: 100px 0px;
}

section#about .about-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding: 60px 60px;
}

section#about .about-wrapper .section-left {
    position: relative;
    max-width: 48%;
    width: 100%;
}

section#about .about-wrapper .section-right {
    position: relative;
    text-align: center;
    max-width: 55%;
    width: 100%;
}

section#about .about-wrapper .section-right img {
    max-width: 812px;
    width: 100%;
    height: auto;
}

section#about .about-wrapper .section-left img {
    max-width: 123px;
    width: 100%;
    height: auto;
}

section#about .about-wrapper .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
}

/* About End */
section#shariaFatwa {
    width: 100%;
    background-image: url(../images/sharia-fatwa-background.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    padding: 100px 0px;
}

section#shariaFatwa .section-content {
    text-align: center;
}

section#shariaFatwa .section-content .text-paragraph {
    margin: 0 auto;
}

section#shariaFatwa .section-content .fatwa-list {
    display: flex;
    justify-content: space-evenly;
    margin-top: 50px;
    flex-wrap: wrap;
}

section#shariaFatwa .section-content .fatwa-list .fatwa-single {
    max-width: 30%;
    width: 100%;
    margin-bottom: 30px;
}

section#shariaFatwa .section-content .fatwa-list .fatwa-single a {
    text-decoration: none;
    color: #80BD65;
    display: inline-flex;
    align-items: center;
    margin-top: 10px;
}

section#shariaFatwa .section-content .fatwa-list .fatwa-single a i {
    font-size: 20px;
    margin-right: 5px;
}

.RTL section#shariaFatwa .section-content .fatwa-list .fatwa-single a i {
    margin-right: 0px;
    margin-left: 5px;
}

section#appIdea {
    padding: 100px 0px;
    background-color: #FBFBFD;
    position: relative;
}

section#appIdea .appIdea-wrapper {
    max-width: 1400px;
}

section#appIdea::before {
    content: '';
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    background-image: url(../images/idea-icon-bg.png);
    background-position: center left;
    background-repeat: no-repeat;
    width: 180px;
    height: 389px;
    z-index: 1;
}

section#appIdea .appIdea-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    position: relative;
    z-index: 2;
}

section#appIdea .appIdea-wrapper .section-left {
    max-width: 45%;
    width: 100%;
}

section#appIdea .appIdea-wrapper .section-left .text-paragraph {
    max-width: 540px;
}

section#appIdea .appIdea-wrapper .section-right {
    max-width: 45%;
    width: 100%;
    text-align: center;
}

section#appIdea .appIdea-wrapper .section-right img {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
}

/* Our Distinguishes */
section#appObjectives {
    width: 100%;
    background-image: url(../images/third-bg.png);
    background-size: cover;
    background-repeat: no-repeat;
    padding: 100px 0px;
}

section#appObjectives .appObjectives-wrapper .section-content {
    text-align: center;
}

section#appObjectives .appObjectives-wrapper .section-content .secondary-heading {
    margin-bottom: 54px;
}

section#appObjectives .appObjectives-wrapper .section-content .objectives {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

section#appObjectives .appObjectives-wrapper .section-content .objectives .single-objective {
    max-width: 21%;
    width: 100%;
    margin-bottom: 25px;
}

section#appObjectives .appObjectives-wrapper .section-content .objectives .single-objective .objective-icon {
    margin-bottom: 0px;
}

section#appObjectives .appObjectives-wrapper .section-content .objectives .single-objective .objective-heading {
    color: #fff;
    font-weight: 700;
    font-size: 28px;
    margin-bottom: 10px;
}

/* Our distingushes end */


section#downloadApp {
    background-image: url(../images/download-app-background.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 90px 0px;
}

section#downloadApp .downloadApp-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

section#downloadApp .downloadApp-wrapper .section-left {
    max-width: 500px;
    width: 100%;
}

section#downloadApp .downloadApp-wrapper .section-left .secondary-heading {
    color: #fff;
    margin-bottom: 10px;
}

section#downloadApp .downloadApp-wrapper .section-left .text-paragraph {
    color: #fff;
    margin: 0px;
    font-size: 20px;
}


section#applicationSteps {
    padding: 60px 0px;
    background-color: #fff;
}

section#applicationSteps .applicationSteps-wrapper .section-content {
    margin-bottom: 60px;
}

section#applicationSteps .applicationSteps-wrapper .section-content .secondary-heading {
    margin-bottom: 10px;
}

section#applicationSteps .applicationSteps-wrapper .section-content .text-paragraph {
    margin: 0px;
}

section#applicationSteps .applicationSteps-wrapper .section-steps {
    display: flex;
    justify-content: space-between;
}

section#applicationSteps .applicationSteps-wrapper .section-steps .step-column {
    max-width: 480px;
    width: 100%;
}

section#applicationSteps .applicationSteps-wrapper .section-steps .step-column .single-step {
    margin-bottom: 50px;
}

section#applicationSteps .applicationSteps-wrapper .section-steps .step-column>div.single-step:last-of-type {
    margin-bottom: 0px;
}

section#applicationSteps .applicationSteps-wrapper .section-steps .step-column .single-step .step-icon {
    max-width: 95px;
    width: 100%;
    margin: 0 0 10px;
}

section#applicationSteps .applicationSteps-wrapper .section-steps .step-column .single-step .step-heading {
    color: #066295;
    font-weight: 500;
    margin: 0 0 10px 10px;
    font-size: 24px;
}

section#applicationSteps .applicationSteps-wrapper .section-steps .step-column .single-step .step-description {
    color: #828282;
    font-weight: 400;
    margin: 0 0 0 10px;
    font-size: 18px;
}

section#applicationSteps .applicationSteps-wrapper .section-steps .step-column-seperator {
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Screen shot start */
section#screenshots {
    padding: 100px 0px;
    background-image: url(../images/hoetawkeelWorksBG.png);
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
}

/* section#screenshots::before{
    content: '';
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    background-image: url(../images/slider-overlay.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 95px;
    height: 400px;
} */
section#screenshots .screenshots-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

section#screenshots .screenshots-wrapper .section-left {
    max-width: 35%;
    width: 100%;
}

section#screenshots .screenshots-wrapper .section-left .section-icon {
    max-width: 150px;
    width: 100%;
    margin: 0 0 10px;
}

section#screenshots .screenshots-wrapper .section-left .secondary-heading {
    margin: 0 0 10px;
}

section#screenshots .screenshots-wrapper .section-left .text-paragraph {
    font-size: 18px;
    margin: 0 0 20px;
}

section#screenshots .screenshots-wrapper .section-left>p.text-paragraph:last-of-type {
    margin: 0 0 50px;
}

section#screenshots .screenshots-wrapper .section-left .slider-navigation {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

section#screenshots .screenshots-wrapper .section-left .slider-navigation #slider-points {
    display: flex;
    align-items: center;
}

section#screenshots .screenshots-wrapper .section-left .slider-navigation #slider-points .slide-pointer {
    display: inline-block;
    cursor: pointer;
    height: 8px;
    width: 8px;
    background-color: #DDDDDD;
    margin-right: 8px;
    border-radius: 10px;
    transition: all 0.3s ease-in-out;
}

section#screenshots .screenshots-wrapper .section-left .slider-navigation #slider-points .slide-pointer.active {
    background-color: #3F9A97;
    height: 10px;
    width: 10px;
    transition: all 0.3s ease-in-out;
}

section#screenshots .screenshots-wrapper .section-left .slider-navigation #slider-points>span.slide-pointer:last-of-type {
    margin-right: 0px;
}

section#screenshots .screenshots-wrapper .section-left .slider-navigation .slider-arrows {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 55px;
    width: 100%;
}

section#screenshots .screenshots-wrapper .section-left .slider-navigation .slider-arrows span svg {
    max-width: 24px;
    width: 100%;
    fill: #3F9A97;
    cursor: pointer;
}

section#screenshots .screenshots-wrapper .section-left .slider-navigation .slider-arrows span.left-control svg {
    transform: rotate(180deg);
}

section#screenshots .screenshots-wrapper .section-left .slider-navigation .slider-arrows span.disabled svg {
    fill: #ccc;
}

section#screenshots .screenshots-wrapper .section-right {
    max-width: 60%;
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: space-around;
}

section#screenshots .screenshots-wrapper .section-right>div {
    max-width: 380px;
    width: 100%;
    overflow: hidden;
}

section#screenshots .screenshots-wrapper .section-right>div .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin: 0;
}

section#screenshots .screenshots-wrapper .section-right>div .btn-group {
    justify-content: center;
}

section#screenshots .screenshots-wrapper .section-right>div .btn-group>a.btn {
    max-width: 120px;
    display: block;
    height: auto;
}

section#screenshots .screenshots-wrapper .section-right>div .btn-group>a.btn>img {
    width: 100%;
}

/* screen shot ends */

/* Tawkeel Agents start */
section#agents {
    width: 100%;
    background-image: url(../images/third-bg.png);
    background-size: cover;
    background-repeat: no-repeat;
    padding: 100px 0px;
}

section#agents .agents-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding: 60px 60px;
}

section#agents .agents-wrapper .section-left {
    position: relative;
    max-width: 38%;
    width: 100%;
}

section#agents .agents-wrapper .section-right {
    position: relative;
    text-align: center;
    max-width: 60%;
    width: 100%;
}

section#agents .agents-wrapper .section-right img {
    width: 100%;
    max-width: 940px;
    height: auto;
}

section#agents .agents-wrapper .section-left img {
    max-width: 123px;
    width: 100%;
    height: auto;
}

section#agents .agents-wrapper .registerAgent {
    margin-right: 10px;
}

body.RTL #agents .agents-wrapper .registerAgent {
    margin-right: 0px;
    margin-left: 10px;
}

/* Tawkeel agents ends */



section#helpCenter {
    padding: 60px 0px;
    background-color: #fff;
}

section#helpCenter .helpCenter-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

section#helpCenter .helpCenter-wrapper .section-left {
    max-width: 525px;
    width: 100%;
}

section#helpCenter .helpCenter-wrapper .section-left .section-icon {
    max-width: 40px;
    width: 100%;
    margin: 0 0 10px;
}

section#helpCenter .helpCenter-wrapper .section-left .secondary-heading {
    margin: 0 0 10px;
}

section#helpCenter .helpCenter-wrapper .section-left .text-paragraph {
    margin: 0 0 40px;
    font-size: 22px;
}

section#helpCenter .helpCenter-wrapper .section-right img {
    max-width: 800px;
}

section#news {
    padding: 100px 0px 0px;
    background-color: #FFFFFF;
}

section#news .news-wrapper {
    display: flex;
    flex-wrap: wrap;
}

section#news .news-wrapper .section-top {
    margin: 0 auto 50px;
    width: 100%;
    text-align: center;
}

section#news .news-wrapper .secondary-heading {
    color: #000000;
}

section#news .news-wrapper .news-list {
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin: 50px 0px 100px;
}

section#news .news-wrapper .news-list>a {
    display: block;
    max-width: 180px;
    margin: 0px 40px;
    width: 100%;
}

section#news .news-wrapper .news-list>a>img {
    width: 100%
}

section#news .news-wrapper .news-card-list {
    width: 100%;
    display: grid;
    grid-template-columns: 30% 30%;
    margin-bottom: 50px;
    justify-content: center;
    column-gap: 30px;
}

section#news .news-wrapper .news-card-list .news-card {
    width: 100%;
    border-radius: 5px;
    background-color: #fff;
    overflow: hidden;
    text-align: left;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}

.RTL section#news .news-wrapper .news-card-list .news-card {
    text-align: right;
}

section#news .news-wrapper .news-card-list .news-card .news-image {
    width: 100%;
    height: 250px;
    object-fit: cover;
    object-position: top;
    border-radius: 5px;
}

section#news .news-wrapper .news-card-list .news-card .news-title {
    color: #000;
    font-weight: 600;
    font-size: 24px;
    margin: 10px 0 20px;
    text-decoration: none;
    display: block;
}

section#news .news-wrapper .news-card-list .news-card .news-description {
    color: #3C3C3C;
    font-weight: 500;
    font-size: 17px;
    line-height: 1.5;
    margin: 10px 0 20px;
    display: block;
}

section#news .news-wrapper .news-card-list .news-card .news-link {
    font-weight: 500;
    text-decoration: none;
    color: #fff;
    background-color: #C29F60;
    display: inline-block;
    padding: 5px 15px;
    border-radius: 50px;
}

/* section#news .news-wrapper .section-right{
    max-width: 50%;
    width: 100%;
    margin-right: 100px;
}
.RTL section#news .news-wrapper .section-right{
    margin-right: 0px;
    margin-left: 100px;
} */
/* gallery start */
section#gallery {
    width: 100%;
    background-image: url(../images/about.png);
    background-size: cover;
    background-repeat: no-repeat;
    padding: 100px 0px;
}

section#gallery .gallery-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding: 60px 60px;
}

section#gallery .gallery-wrapper .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
}

section#gallery .gallery-wrapper .section-right {
    z-index: 1;
}

section#gallery .gallery-wrapper .section-right .logo-img {
    max-width: 123px;
    width: 100%;
    height: auto;
}

section#gallery .gallery-wrapper .section-right .photo-gallery {
    width: 100%;
    display: grid;
    justify-content: space-between;
    grid-template-columns: 19% 19% 19% 19% 19%;
}

section#gallery .gallery-wrapper .section-right .photo-gallery .single-image {
    max-height: 250px;
    width: 100%;
    cursor: pointer;
    position: relative;
    margin-bottom: 15px;
    border-radius: 25px;
    overflow: hidden;
}

section#gallery .gallery-wrapper .section-right .photo-gallery .single-image::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: inline-block;
    z-index: 99;
    background-color: #000;
    opacity: 0.4;
}

section#gallery .gallery-wrapper .section-right .photo-gallery .single-image video::-webkit-media-controls-panel {
    display: none !important;
    opacity: 1 !important;
}

section#gallery .gallery-wrapper .section-right .photo-gallery .single-image video,
section#gallery .gallery-wrapper .section-right .photo-gallery .single-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative;
    z-index: 1;
    object-position: center;
}

section#gallery .gallery-wrapper .section-right .photo-gallery .single-image i {
    position: absolute;
    top: 50%;
    left: 50%;
    color: #fff;
    transform: translate(-50%, -50%);
    z-index: 100;
    font-size: 30px;
}

/* gallery end */

/* Certificates */
section#certificate {
    width: 100%;
    background-image: url(../images/third-bg.png);
    background-size: cover;
    background-repeat: no-repeat;
    padding: 80px 0px;
}

section#certificate .certificate-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    /* padding: 60px 60px; */
}

section#certificate .certificate-wrapper .section-certificate .certificate-list .single-certificate {
    display: block;
    text-decoration: none;
    margin: 0 0 20px;
    background: #62626280 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 20px;
    padding: 40px 100px 30px 50px;
    position: relative;
}

section#certificate .certificate-wrapper .section-certificate .certificate-list .single-certificate .certificate-arrow {
    color: #C89D55;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 50%;
    padding: 5px;
    font-size: 20px;
    font-weight: 800;
    position: absolute;
    right: 15px;
    top: 15px;
}

section#certificate .certificate-wrapper .section-certificate .certificate-list .single-certificate h4 {
    color: #C29E60;
    ;
    font-weight: 500;
    font-size: 28px;
    margin: 0 0 10px;
}

section#certificate .certificate-wrapper .section-certificate .certificate-list .single-certificate p {
    color: #CECECE;
    font-weight: 400;
    font-size: 22px;
    line-height: 1.5;
    margin: 0 0 10px;
}

section#certificate .certificate-wrapper .section-certificate .certificate-list .single-certificate p strong {
    font-weight: 500;
}

section#certificate .certificate-wrapper .section-certificate .certificate-list .single-certificate a {
    font-weight: 500;
    text-decoration: none;
    color: #80BD65;
}

/* Certificates end */
section#shariaFatwa .modal,
section#news .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
}

section#shariaFatwa .modal .moda-overlay,
section#news .modal .moda-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.8;
}

section#shariaFatwa .modal .moda-close,
section#news .modal .moda-close {
    position: fixed;
    top: 25px;
    right: 25px;
    cursor: pointer;
    background-color: #fff;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 35px;
    width: 35px;
    z-index: 99999999;
    border-radius: 50px;
    font-size: 20px;
}

section#shariaFatwa .modal .modal-body {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99999;
    width: calc(100% - 50px);
    max-height: 95vh;
    overflow: auto;
    text-align: center;
    background-color: #222;
}

section#news .modal .modal-body {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99999;
    width: 700px;
    text-align: center;
    background-color: #222;
}

section#shariaFatwa .modal .modal-body img,
section#news .modal .modal-body img {
    width: 100%;
    display: block;
}

section#shariaFatwa .modal .modal-body video,
section#news .modal .modal-body video {
    width: 100%;
}

section#shariaFatwa .modal .modal-body p,
section#news .modal .modal-body p {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 999909;
    width: 100%;
    text-align: center;
    background: #222;
    color: #fff;
    padding: 5px;
    margin: 0;
    font-size: 14px;
}

/* Contact Start */
section#contact {
    padding: 70px 0px;
    background-image: url(../images/hoetawkeelWorksBG.png);
    background-size: cover;
    background-repeat: no-repeat;
}

section#contact .contact-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;
}

section#contact .contact-wrapper::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    left: 50%;
    width: 2px;
    height: 85%;
    background-color: #C29E60;
    transform: translateX(-50%);
}

section#contact .contact-wrapper .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.1);
}

section#contact .contact-wrapper .section-left {
    max-width: 48%;
    width: 100%;
    z-index: 1;
}

section#contact .contact-wrapper .section-left .form-container {
    background-color: transparent;
    padding: 30px;
    border-radius: 20px;
}

section#contact .contact-wrapper .section-left .form-container .secondary-heading {
    margin: 0 0 30px;
}

section#contact .contact-wrapper .section-left .form-container form .form-row {
    display: flex;
    align-items: center;
    margin: 0 0 20px;
}

section#contact .contact-wrapper .section-left .form-container form .form-row>div {
    flex: 1;
    margin: 0 10px;
}

section#contact .contact-wrapper .section-left .form-container .MuiOutlinedInput-root {
    padding: 0px;
}

section#contact .contact-wrapper .form-container form .form-row>div textarea,
section#contact .contact-wrapper .form-container form .form-row>div input {
    background-color: #fff;
    /* padding: 4px 14px; */
}

.RTL section#contact .contact-wrapper .section-left .form-container form .form-row>div>label[data-shrink=false] {
    transform: translate(-15px, 16px) scale(1);
    right: 0;
    left: auto;
}

.RTL section#contact .contact-wrapper .section-left .form-container form .form-row>div>label[data-shrink=true] {
    right: 15px;
    padding: 0px 15px;
    float: right;
    max-width: 100%;
    transform: translateY(-10px);
    background-color: #fff;
    display: inline-block;
    width: auto;
    left: auto;
}

.RTL section#contact .contact-wrapper .section-left .form-container form .form-row>div textarea,
.RTL section#contact .contact-wrapper .section-left .form-container form .form-row>div input,
.RTL section#contact .contact-wrapper .section-left .form-container form .form-row>div p {
    text-align: right;
    /* font-family: 'Tajawal'; */
}

section#contact .contact-wrapper .section-left .form-container form .form-footer {
    margin: 0 10px;
    text-align: right;
}

section#contact .contact-wrapper .section-left .form-container form label.Mui-focused {
    color: #C29F60 !important;
    background-color: #fff;
    display: none;
    border: none;
}

section#contact .contact-wrapper .section-left .form-container form div.Mui-focused fieldset {
    border-color: #C29F60 !important;
    border: none;
}

section#contact .contact-wrapper .section-right {
    max-width: 48%;
    width: 100%;
    z-index: 1;
    padding: 30px;
}

section#contact .contact-wrapper .section-right iframe {
    height: 485px;
    width: 100%;
    border: 0px;
    border-radius: 20px;
    margin-bottom: -4px;
}

section#contact .contact-wrapper .section-right .social-links {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 40px;
}

section#contact .contact-wrapper .section-right .social-links .social-single {
    max-width: 50%;
    width: 100%;
    display: flex;
    align-items: center;
    text-decoration: none;
    margin-bottom: 30px;
}

section#contact .contact-wrapper .section-right .social-links .social-single i {
    color: #fff;
    font-size: 35px;
    background-color: #C29F60;
    padding: 10px 12px;
    border-radius: 50%;
}

section#contact .contact-wrapper .section-right .social-links .social-single .social-text {
    margin-left: 20px;
}

.RTL section#contact .contact-wrapper .section-right .social-links .social-single .social-text {
    margin-right: 20px;
    margin-left: 0px;
}

section#contact .contact-wrapper .section-right .social-links .social-single .social-text h5 {
    color: #fff;
    font-size: 22px;
    margin: 0;
    font-weight: 500;
}

section#contact .contact-wrapper .section-right .social-links .social-single .social-text h6 {
    color: #CCCCCC;
    font-size: 16px;
    margin: 0;
    font-weight: 400;
}

/* Contact end */
/* Responsive */

@media only screen and (max-width: 1680px) {
    /* section#screenshots .screenshots-wrapper .section-right #screenshot_slider::before {
        height: 630px;
        width: 318px;
    }
    section#screenshots .screenshots-wrapper .section-right #screenshot_slider .swiper-slide {
        text-align: left;
        justify-content: inherit;
        align-items: inherit;
        background: none;
    } */
    /* section#screenshots .screenshots-wrapper .section-right #screenshot_slider .swiper-slide{
        min-width: 278px;
        margin-right: 30px!important
    } */
    /* section#screenshots .screenshots-wrapper .section-right #screenshot_slider .swiper-slide img {
        min-width: 278px;
        max-width: 278px;
    }  */
}


@media only screen and (max-width: 1600px) {
    section#news .news-wrapper .section-left {
        margin-right: 50px;
        max-width: 250px;
    }

    .RTL section#news .news-wrapper .section-left {
        margin-right: 0px;
        margin-left: 50px;
    }
}

@media only screen and (max-width: 1420px) {
    section#home .home-wrapper .section-right img {
        max-width: 100%;
    }

    section#appObjectives .appObjectives-wrapper .section-content .objectives .single-objective .objective-heading {
        font-size: 24px;
    }

    section#appObjectives .appObjectives-wrapper .section-content .objectives .single-objective .objective-icon {
        max-width: 140px;
    }

    section#appObjectives .appObjectives-wrapper .section-content .objectives .single-objective .objective-heading {
        font-size: 20px;
    }

    section#downloadApp .downloadApp-wrapper .section-left .text-paragraph {
        font-size: 16px;
    }

    section#applicationSteps .applicationSteps-wrapper .section-steps .step-column-seperator img {
        max-width: 250px;
        width: 100%;
    }

    section#applicationSteps .applicationSteps-wrapper .section-steps .step-column .single-step .step-heading {
        font-size: 20px;
    }

    section#applicationSteps .applicationSteps-wrapper .section-steps .step-column .single-step .step-description {
        font-size: 16px;
    }

    section#applicationSteps .applicationSteps-wrapper .section-steps .step-column {
        max-width: 400px;
    }

    section#screenshots .screenshots-wrapper .section-left .text-paragraph {
        font-size: 16px;
    }

    section#helpCenter .helpCenter-wrapper .section-left .text-paragraph {
        font-size: 18px;
    }

    section#helpCenter .helpCenter-wrapper .section-left {
        max-width: 400px;
    }

    section#helpCenter .helpCenter-wrapper .section-right img {
        max-width: 550px;
    }

    section#screenshots .screenshots-wrapper .section-right>div {
        max-width: 320px;
        width: 100%;
        overflow: hidden;
    }

    /* section#news .news-wrapper .section-right {
        max-width: 570px;
    } */
    section#news .news-wrapper .section-right .photo-gallery .single-image {
        max-width: 180px;
        max-height: 180px;
    }

    section#news .news-wrapper .section-right .photo-gallery .single-image video,
    section#news .news-wrapper .section-right .photo-gallery .single-image img {
        max-width: 180px;
        height: 180px;
    }

    section#news .news-wrapper .section-left {
        max-width: 240px;
        width: 100%;
        margin-right: 100px;
    }

    section#gallery .gallery-wrapper .section-right .photo-gallery {
        grid-template-columns: 24% 24% 24% 24%;
    }
}

@media only screen and (max-width: 1300px) {

    section#news .news-wrapper .section-left,
    section#news .news-wrapper .section-right {
        margin-right: 30px;
    }

    .RTL section#news .news-wrapper .section-left,
    .RTL section#news .news-wrapper .section-right {
        margin-right: 0px;
        margin-left: 30px;
    }
}


@media only screen and (max-width: 1200px) {
    section#home::before {
        width: 50%;
    }

    section#home .home-wrapper .section-left p {
        margin-bottom: 20px;
    }

    section#home .play-button {
        margin-top: 60px;
    }

    section#home .home-wrapper .section-left img {
        max-width: 140px;
    }

    /* section#news .news-wrapper .section-right {
        max-width: 400px;
    } */
    section#agents .agents-wrapper .registerAgent {
        margin-bottom: 10px;
    }

    section#appIdea .appIdea-wrapper .section-right img {
        max-width: 380px;
    }

    section#applicationSteps .applicationSteps-wrapper .section-steps .step-column {
        max-width: 350px;
    }

    section#contact .contact-wrapper .section-left .form-container {
        padding: 30px 15px;
    }

    section#screenshots .screenshots-wrapper .section-right>div {
        max-width: 280px;
        width: 100%;
        overflow: hidden;
    }

    section#contact .contact-wrapper .section-left .form-container form .form-row input.MuiOutlinedInput-input.MuiInputBase-input {
        height: 40px;
        padding: 4px 14px;
        font-size: 14px;
    }

    section#contact .contact-wrapper .section-left .form-container form .form-row label.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated {
        font-size: 14px;
    }

    section#shariaFatwa .section-content .fatwa-list .fatwa-single {
        max-width: 45%;
        margin-bottom: 30px;
    }

    section#news .news-wrapper .news-card-list {
        grid-template-columns: 32% 32% 32%;
    }

    section#news .news-wrapper .news-card-list .news-card .news-image {
        height: 200px;
    }


}

@media only screen and (max-width: 1110px) {
    section#news .news-wrapper .section-left {
        max-width: 200px;
    }
}

@media only screen and (max-width: 1050px) {
    section#home::before {
        width: 60%;
    }

    section#home .play-button button span,
    section#home .play-button button,
    section#home .play-button::after,
    section#home .play-button::before {
        height: 60px;
        width: 60px;
    }

    section#appObjectives .appObjectives-wrapper .section-content .objectives .single-objective {
        max-width: 48%;
        margin-bottom: 50px;
    }

    section#downloadApp .downloadApp-wrapper {
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    section#downloadApp .downloadApp-wrapper .section-left {
        max-width: 500px;
        width: 100%;
        text-align: center;
        margin-bottom: 50px;
    }

    section#applicationSteps .applicationSteps-wrapper .section-steps .step-column-seperator {
        display: none;
    }

    section#screenshots .screenshots-wrapper .section-right>div {
        max-width: 200px;
        width: 100%;
        overflow: hidden;
    }

    section#screenshots .screenshots-wrapper .section-right>div .btn-group>a.btn {
        margin: 0 10px;
    }

    section#helpCenter .helpCenter-wrapper {
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    section#helpCenter .helpCenter-wrapper .section-left {
        text-align: center;
        margin-bottom: 40px;
    }

    section#helpCenter .helpCenter-wrapper .section-left .btn-group {
        justify-content: center;
    }

    section#contact {
        padding: 20px 0;
        position: relative;
        background-color: #fff;
    }

    section#contact .contact-wrapper {
        padding-top: 50px;
    }

    section#contact .contact-wrapper::after {
        display: none;
    }

    section#contact .contact-wrapper .section-left {
        max-width: 550px;
        position: relative;
        z-index: 2;
    }

    section#contact .contact-wrapper .section-right {
        max-width: calc(100% - 580px);
    }

    section#contact .contact-wrapper .section-right .social-links .social-single {
        max-width: 100%;
        width: 100%;
    }

    section#contact .contact-wrapper .section-left .form-container {
        box-shadow: 3px 0px 15px 0px #cdd7c8;
    }

    footer .footer-wrapper .footer-row {
        flex-wrap: wrap;
    }

    footer .footer-wrapper .footer-row .footer-column {
        max-width: 45%;
        margin-bottom: 50px;
    }

    footer .footer-wrapper .footer-row>div.footer-column:first-of-type {
        order: 1;
    }

    footer .footer-wrapper .footer-row>div.footer-column:last-of-type {
        order: 2;
        display: flex;
        flex-direction: column;
    }

    footer .footer-wrapper .footer-row>div.footer-column:nth-of-type(2) {
        order: 3;
        margin-bottom: 0px;
    }

    footer .footer-wrapper .footer-row>div.footer-column:nth-of-type(3) {
        order: 4;
        margin-bottom: 0px;
    }

    footer .footer-wrapper .footer-row>div.footer-column:first-of-type .text-paragraph {
        margin-bottom: 0;
    }

    footer .footer-wrapper .footer-row>div.footer-column:first-of-type .payment-methods {
        margin-top: 10px;
    }

    footer .footer-wrapper .footer-row>div.footer-column:last-of-type .text-paragraph {
        margin-bottom: 10px;
    }

    footer .footer-wrapper .footer-row .footer-column .btn-apple svg,
    footer .footer-wrapper .footer-row .footer-column .btn-google svg {
        height: auto;
    }
}

@media only screen and (max-width: 985px) {

    .RTL section#news .news-wrapper .section-left,
    section#news .news-wrapper .section-left {
        order: 1;
        max-width: 100%;
        margin-left: 0;
        margin-right: 0;
        text-align: center;
    }

    section#news .news-wrapper .section-left .news-list {
        flex-direction: row;
        justify-content: space-evenly;
        margin-top: 40px;
    }

    section#news .news-wrapper .section-right,
    .RTL section#news .news-wrapper .section-right {
        order: 3;
        margin-left: 0;
        margin-right: 0;
        max-width: 100%;
        margin-top: 50px;
        text-align: center;
    }

    section#news .news-wrapper .section-right .photo-gallery {
        justify-content: space-evenly;
        margin-top: 50px;
    }

    section#news .news-wrapper .section-certificate {
        order: 2;
        text-align: center;
        width: 100%;
        margin-top: 60px;
    }

    section#news .news-wrapper .section-certificate .certificate-list {
        display: flex;
        justify-content: space-evenly;
        margin-top: 50px;
    }
}

@media only screen and (max-width: 860px) {
    section#screenshots .screenshots-wrapper .section-right {
        max-width: 60%;
    }

    section#screenshots .screenshots-wrapper .section-left {
        max-width: 40%;
    }

    section#applicationSteps .applicationSteps-wrapper .section-steps .step-column {
        max-width: 300px;
    }

    section#applicationSteps .applicationSteps-wrapper .section-steps .step-column .single-step .step-description {
        font-size: 14px;
    }

    section#shariaFatwa .section-content .fatwa-list {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    section#shariaFatwa .section-content .fatwa-list .fatwa-single {
        max-width: 100%;
        margin-bottom: 30px;
    }


    section#contact .contact-wrapper {
        flex-direction: column-reverse;
    }

    section#contact .contact-wrapper .section-right {
        max-width: 100%;
    }

    section#contact .contact-wrapper .section-right .secondary-heading {
        text-align: center;
    }

    section#contact .contact-wrapper .section-right .social-links {
        justify-content: center;
    }

    section#contact .contact-wrapper .section-right .social-links .social-single {
        max-width: 24%;
        justify-content: center;
        flex-direction: column;
        text-align: center;
    }

    section#contact .contact-wrapper .section-right .social-links .social-single .social-text,
    .RTL section#contact .contact-wrapper .section-right .social-links .social-single .social-text {
        margin-right: 0px;
        margin-left: 0px;
    }


    section#news .news-wrapper .section-left {
        max-width: 100%;
        margin-bottom: 50px;
    }

    section#news .news-wrapper .section-left,
    .RTL section#news .news-wrapper .section-left {
        margin-right: 0px;
        margin-left: 0px;
    }

    section#news .news-wrapper .section-left .secondary-heading,
    section#news .news-wrapper .section-right .secondary-heading {
        text-align: center;
        margin-bottom: 30px;
    }

    section#news .news-wrapper .section-left .news-list {
        flex-direction: row;
        justify-content: space-between;
        gap: 30px;
    }

    section#news .news-wrapper .section-right {
        max-width: 100%;
    }

    section#news .news-wrapper .section-right .photo-gallery {
        text-align: center;
        justify-content: center;
    }

    section#news .news-wrapper .news-card-list {
        grid-template-columns: 48% 48%;
    }
}

@media only screen and (max-width: 768px) {

    .topbar-whatsapp,
    .topbar-phone {
        padding: 0 8px !important;
    }

    .btn-lang {
        padding: 0 8px !important;
    }

    .topbar-whatsapp span,
    .topbar-phone span {
        display: none;
    }

    section#home::before {
        width: 80%;
    }

    section#home .home-wrapper .section-left {
        max-width: 100%;
    }

    section#home .home-wrapper .section-left p {
        color: #fff;
    }

    section#about .about-wrapper {
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    section#about .about-wrapper .section-left,
    section#about .about-wrapper .section-right {
        max-width: 100%;
        margin-bottom: 20px;
        text-align: center;
    }

    section#agents .agents-wrapper {
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    section#agents .agents-wrapper .section-left,
    section#agents .agents-wrapper .section-right {
        max-width: 100%;
        margin-bottom: 60px;
        text-align: center;

    }

    section#appIdea .appIdea-wrapper {
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    section#appIdea .appIdea-wrapper .section-left {
        max-width: 100%;
        text-align: center;
        margin-bottom: 50px;
    }

    section#appIdea .appIdea-wrapper .section-left .text-paragraph {
        max-width: 550px;
        margin: 0 auto;
    }

    section#appIdea .appIdea-wrapper .section-right {
        max-width: 100%;
    }

    section#screenshots .screenshots-wrapper {
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    section#screenshots .screenshots-wrapper .section-left {
        max-width: 550px;
        text-align: center;
    }

    section#screenshots .screenshots-wrapper .section-right {
        max-width: 100%;
    }

    section#screenshots .screenshots-wrapper .section-right #screenshot_slider::before {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    section#screenshots .screenshots-wrapper .section-right #screenshot_slider .swiper-slide {
        text-align: center;
        justify-content: center;
        align-items: center;
    }

    section#screenshots .screenshots-wrapper .section-left .section-icon {
        max-width: 120px;
    }

    section#contact .contact-wrapper .section-left {
        max-width: 100%;
    }

    section#applicationSteps .applicationSteps-wrapper .section-steps {
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }

    section#applicationSteps .applicationSteps-wrapper .section-steps .step-column>div.single-step:last-of-type,
    section#applicationSteps .applicationSteps-wrapper .section-steps .step-column .single-step {
        margin-bottom: 50px;
        text-align: center;
    }

    section#applicationSteps .applicationSteps-wrapper .section-steps>div.step-column:last-of-type>div.single-step:last-of-type {
        margin-bottom: 0px;
    }

    section#applicationSteps .applicationSteps-wrapper .section-steps .step-column {
        max-width: 500px;
    }

    section#applicationSteps .applicationSteps-wrapper .section-steps .step-column .single-step .step-heading,
    section#applicationSteps .applicationSteps-wrapper .section-steps .step-column .single-step .step-description {
        margin-left: 0;
    }

    section#appObjectives .appObjectives-wrapper .section-content .objectives {
        flex-direction: column;
        align-items: center;
    }

    section#appObjectives .appObjectives-wrapper .section-content .objectives .single-objective {
        max-width: 100%;
        margin-bottom: 50px;
    }

    section#agents {
        padding: 0px 0px;
    }

    section#gallery .gallery-wrapper .section-right .photo-gallery {
        grid-template-columns: 32% 32% 32%;
    }
    section#gallery .gallery-wrapper .section-right .photo-gallery .single-image {
        max-height: 140px;
        border-radius: 15px;
    }
}

@media only screen and (max-width: 640px) {
    section#home::before {
        width: 100%;
    }

    section#home .home-wrapper .section-right {
        display: none;
    }

    section#home .home-wrapper .section-left {
        max-width: 100%;
        text-align: center;
    }

    section#home .home-wrapper .section-left p {
        color: #fff;
        max-width: 100%;
    }

    section#home .home-wrapper .section-left .btn-group {
        justify-content: center;
    }

    section#home .play-button {
        display: inline-flex;
    }

    section#helpCenter .helpCenter-wrapper .section-right img {
        width: 100%;
    }

    section#contact .contact-wrapper .section-right .social-links .social-single {
        max-width: 48%;
    }

    section#news .news-wrapper .section-certificate .certificate-list {
        flex-direction: column;
    }

    section#news .news-wrapper .news-list>a {
        margin: 0px 10px;
    }

    section#agents .agents-wrapper {
        padding: 60px 30px;
    }
}

@media only screen and (max-width: 550px) {
    section#screenshots .screenshots-wrapper .section-right {
        flex-direction: column;
    }

    section#screenshots .screenshots-wrapper .section-right>div {
        max-width: 240px;
        width: 100%;
        overflow: hidden;
        margin: 0 auto;
    }

    section#screenshots .screenshots-wrapper .section-right>div:first-of-type {
        margin: 0 auto 30px;
    }

    section#screenshots .screenshots-wrapper .section-right>div .swiper-slide img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        margin: 0;
    }

    section#shariaFatwa .modal .modal-body {
        width: calc(100% - 25px);
    }

    section#news .modal .modal-body {
        width: calc(100% - 25px);
        padding-bottom: 20px;
    }

    section#news .news-wrapper .section-left .news-list {
        flex-direction: column;
        align-items: center;
    }

    section#news .news-wrapper .news-card-list {
        grid-template-columns: 100%;
    }

    section#news .news-wrapper .news-card-list .news-card {
        text-align: center;
        align-items: center;
    }

    .RTL section#news .news-wrapper .news-card-list .news-card {
        text-align: center;
    }

    section#gallery .gallery-wrapper {
        padding: 60px 20px;
    }
}

@media only screen and (max-width: 500px) {
    header .topbar .topbar-wrapper {
        padding: 0 20px !important;
    }

    section#home .home-wrapper .section-left img {
        max-width: 100px;
    }

    section#contact .contact-wrapper .section-left .form-container {
        padding: 30px 20px;
    }

    section#contact .contact-wrapper .section-left .form-container form .form-row {
        margin: 0;
        flex-direction: column;
    }

    section#contact .contact-wrapper .section-left .form-container form .form-row>div {
        flex: 1;
        margin: 10px;
        width: 100%;
    }

    section#contact .contact-wrapper .section-left .form-container form .form-footer {
        margin: 15px 0 0;
    }

    section#contact .contact-wrapper .section-right iframe {
        height: 645px;
    }

    section#home::before {
        width: 70%;
    }

    section#screenshots .screenshots-wrapper .section-right #screenshot_slider .swiper-slide img {
        min-width: 200px;
        max-width: 200px;
    }

    section#screenshots .screenshots-wrapper .section-right #screenshot_slider::before {
        height: 450px;
        width: 228px;
    }

    section#news .news-wrapper .section-right .photo-gallery .single-image,
    .RTL section#news .news-wrapper .section-right .photo-gallery .single-image {
        margin-left: 0px;
        margin-right: 0px;
    }

    section#news .news-wrapper .section-right .photo-gallery {
        gap: 10px;
    }

    section#news .news-wrapper .section-right .photo-gallery .single-image {
        max-width: 150px;
        height: 150px;
    }

    section#news .news-wrapper .section-right .photo-gallery .single-image video,
    section#news .news-wrapper .section-right .photo-gallery .single-image img {
        max-width: 150px;
        height: 150px;
    }

    section#certificate .certificate-wrapper .section-certificate .certificate-list .single-certificate h4 {
        font-size: 22px;
    }

    section#certificate .certificate-wrapper .section-certificate .certificate-list .single-certificate p {
        font-size: 16px;
    }

    section#contact .contact-wrapper .section-right .social-links .social-single .social-text h5 {
        font-size: 16px;
    }

    section#gallery .gallery-wrapper .section-right .photo-gallery .single-image {
        /* max-width: 310px;
        max-height: 240px; */
    }

    section#gallery .gallery-wrapper .section-right .photo-gallery .single-image video,
    section#gallery .gallery-wrapper .section-right .photo-gallery .single-image img {
        /* max-width: 360px;
        height: 244px; */
    }

    section#news .news-wrapper .news-card-list .news-card .news-title {
        font-size: 18px;
        margin: 10px 0 10px;
    }

    section#news .news-wrapper .news-card-list .news-card .news-description {
        font-size: 14px;
    }

    section#agents .agents-wrapper .registerAgent {
        margin-right: 0px;
    }

    body.RTL #agents .agents-wrapper .registerAgent {
        margin-right: 0px;
        margin-left: 0px;
    }

    section#contact .contact-wrapper .section-right .social-links {
        justify-content: space-between;
    }

    section#contact .contact-wrapper .section-right .social-links .social-single i {
        font-size: 28px;
    }

    section#about .about-wrapper {
        padding: 60px 20px;
    }
}