section#termsConditionsHome{
    width: 100%;
    padding: 200px 0px 60px;
    background-image: url(../images/hero.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
body.RTL section#termsConditionsHome{
    background-image: url(../images/hero-AR.jpg);
}
/* section#termsConditionsHome::before{
    content: '';
    background-image: url(../images/help-center-background.png);
    display: inline-block;
    position: absolute;
    height: 100%;
    width: 55%;
    top: 0;
    right: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top left;
    z-index: 0;
}
.RTL section#termsConditionsHome::before{
    background-image: url(../images/help-center-background-RTL.png);
    top: 0;
    right: auto;
    left: 0;
    background-position: top right;
} */

section#performerTerms,
section#seekerTerms {
    background-image: url(../images/hoetawkeelWorksBG.png);
    background-size: contain;
    background-repeat: repeat-y;
}
section#termsConditionsHome .termsConditionsHome-wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
section#termsConditionsHome .termsConditionsHome-wrapper .section-left{
    position: relative;
    max-width: 45%;
    width: 100%;
}
section#termsConditionsHome .termsConditionsHome-wrapper .section-left p{
    max-width: 490px;
    margin-bottom: 0px;
}
section#termsConditionsHome .termsConditionsHome-wrapper .section-left .btn-group{
    margin-top: 40px;
}
section#termsConditionsHome .termsConditionsHome-wrapper .section-right{
    position: relative;
    text-align: center;
    max-width: 55%;
    width: 100%;
}
section#termsConditionsHome .termsConditionsHome-wrapper .section-right img{  
    max-width: 650px;
    width: 100%;
}
.terms-container-wrapper{
    display: flex;
    justify-content: space-between;
    background: #62626280 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 20px;
}
.terms-container-wrapper .terms-container{  
    max-width: 70%;
    width: 100%;
    padding: 80px 40px;
}
.terms-container-wrapper .terms-container p{  
    text-align: justify;
}
.terms-container-wrapper .terms-container .text-ordered-list{  
    list-style-position: inside;
    margin: 0;
    padding: 0;
    color: #fff;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.5;
}
.terms-container-wrapper .terms-container .text-unordered-list li{  
    margin: 0 0 10px;
}
.terms-container-wrapper .terms-container .text-unordered-list{  
    list-style-position: inside;
    margin: 0;
    padding: 0;
    color: #fff;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.5;
}
.terms-container-wrapper .terms-container .text-ordered-list li{  
    margin: 0 0 10px;
}
.terms-container-wrapper .terms-conditions-siderbar{  
    max-width: 28%;
    width: 100%;
    padding: 80px 25px;
}
.terms-container-wrapper .terms-conditions-siderbar .terms-conditions-siderbar-container{  
    background: #62626280 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 20px;
    padding: 30px 20px 30px 50px;
    max-height: 85vh;
    overflow-y: auto;
    overflow-x: hidden;
}
.terms-container-wrapper .terms-conditions-siderbar .terms-conditions-siderbar-container li{
    display: flex;
    list-style: none;
    color: #828282;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 10px;
}
.terms-container-wrapper .terms-conditions-siderbar .terms-conditions-siderbar-container > li:last-of-type{
    margin-bottom: 0px;
}
.terms-container-wrapper .terms-conditions-siderbar .terms-conditions-siderbar-container li::before{
    content: attr(data-number) ".";
    display: inline-block;
    min-width: 30px;
    transition: all 0.3s ease-in-out;
}
.terms-container-wrapper .terms-conditions-siderbar .terms-conditions-siderbar-container li a{
    color: #cecece;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.5;
    text-decoration: none;
    display: block;
    transition: all 0.3s ease-in-out;
    width: calc( 100% - 30px );
}
.terms-container-wrapper .terms-conditions-siderbar .terms-conditions-siderbar-container li:hover,
.terms-container-wrapper .terms-conditions-siderbar .terms-conditions-siderbar-container li:hover a,
.terms-container-wrapper .terms-conditions-siderbar .terms-conditions-siderbar-container li a:hover{
    color: #C29F60;
    transition: all 0.3s ease-in-out;
}


@media only screen and (max-width: 1680px){
    section#termsConditionsHome .termsConditionsHome-wrapper .section-right img {
        max-width: 500px;
    }
}

@media only screen and (max-width: 1460px){
    section#termsConditionsHome .termsConditionsHome-wrapper .section-right img {
        max-width: 500px;
    }
    .terms-container-wrapper .terms-conditions-siderbar {
        max-width: 30%;
    }
    .terms-container-wrapper .terms-conditions-siderbar .terms-conditions-siderbar-container {
        padding: 30px;
    }
}
@media only screen and (max-width: 1380px){
    .terms-container-wrapper .terms-container {
        max-width: 65%;
    }
    .terms-container-wrapper .terms-conditions-siderbar {
        max-width: 35%;
    }
}
@media only screen and (max-width: 1150px){
    .terms-container-wrapper .terms-conditions-siderbar {
        display: none;
    }
    .terms-container-wrapper .terms-container {
        max-width: 100%;
    }
    section#termsConditionsHome .termsConditionsHome-wrapper .section-left .btn-group {
        margin-top: 50px;
    }
    section#termsConditionsHome .termsConditionsHome-wrapper .section-right img {
        max-width: 400px;
    }
}
@media only screen and (max-width: 900px){
    .terms-container-wrapper .terms-container {
        padding-left: 0px;
        padding-right: 0px;
    }
}
@media only screen and (max-width: 768px){
    section#termsConditionsHome .termsConditionsHome-wrapper .section-right {
        display: none;
    }
    section#termsConditionsHome .termsConditionsHome-wrapper .section-left p,
    section#termsConditionsHome .termsConditionsHome-wrapper .section-left {
        max-width: 100%;
        text-align: center;
        color: #fff;
    }
    section#termsConditionsHome .termsConditionsHome-wrapper .section-left .btn-group {
        margin-top: 25px;
        justify-content: center;
    }
    section#termsConditionsHome::before {
        width: 45%;
    }
}